.table-review-block {
  display: flex;
  border-radius: 5px;
  width: 120px;
  height: 45px;
  border: 1px solid #CDCDCD;
  background-color: white;
  flex-direction: row;
  cursor: pointer;

}

.table-review-block > * {
  margin: auto;
}

.chief-review-state {
  width: 40px;
  height: 40px;

  .CircularProgressbar-text {
    transform: translate(-30px, 9px);
  }
}

.rev-info p {
  margin: 2px;
  margin-left: 3px;
}

.review-completed {
  opacity: 0.5;
}

.review-warning {
  border: 1px solid rgba(255, 0, 0, 0.7);
}


.rev-info__account {
  font-size: '10px'
}
