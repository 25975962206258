.admin-message-table th, td {
  text-align: center;
}

.admin-message-text {
  text-align: start;
  white-space: pre-wrap;
}

#admin-create-message-button {
  width: 200px;
  height: 40px;
  margin-top: 1rem;
}

.admin-message-table {
  margin-top: 1rem;
}

#admin-notification-message {
  text-align: start;
}
