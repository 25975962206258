.carousel-control.right,
.carousel-control-next {
  background-image: none !important;
}

.carousel-control.left,
.carousel-control-prev {
  background-image: none !important;
}

.carousel-control-prev, .carousel-control-next {
  color: rgb(51, 122, 183) !important;
  height: 500px !important;
  margin-top: 150px !important;
  border-radius: 20px !important;
  font-size: 50px !important;
}

.glyphicon-custom {
  top: 45% !important;
}

.glyphicon-custom:hover {
  color: rgb(46, 92, 148) !important;
}
