.content {
    display: grid;
    grid-template-columns: repeat(5, 50px);
    grid-template-rows: repeat(2, 50px);
    align-items: center;

    .sourceLabel{
      text-align: center;
      grid-column: 1/1;
    }
    .sourceDatepicker{
      grid-column: 2/3;
      min-width: 170px;
    }
    .targetLabel{
      text-align: center;
      grid-column: 1/1;
    }
    .targetDatepicker{
      grid-column: 2/3;
      min-width: 170px;
    }

  }
