.theory-review-top-sidebar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 115px;
  background: #AFAFAF;
  position: fixed;
  z-index: 999;
}

.block-students-debts {
  width: 500px;
  height: 115px;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}

.block-students-list-header {
  width: 500px;
  height: 115px;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #000000;
}


.block-students-questions-header {
  display: flex;
  flex-direction: column;
  height: 115px;
  padding: 5px;
  margin-left: auto;
}

.theory-review-questions-list-header {
  height: 60px;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  align-items: center;
  text-align: center;
  color: #000000;
}

.theory-review-questions-list-header * {
  margin-right: 2.9rem;
}

.theory-review-questions-list-header .mdi-view-list {
  font-size: 30px;
  color: #6D6D6D;
}

.theory-review-questions-list-header .mdi-plus-circle {
  font-size: 30px;
  color: #6D6D6D;
}

.theory-review-questions-list-header label {
  margin-top: 0.5rem;
}

.block-students-debts-header {
  display: flex;
  margin-right: auto;
  margin-top: 3rem;
  margin-left: 5rem;
  width: 500px;
}

.block-students-debts-header span {
  width: 44px;
  height: 28px;
  display: flex;
}

.block-students-debts-header label {
  margin-top: 1.3rem;
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.block-students-debts-header .mdi {
  color: #6D6D6D;
  font-size: 30px;
}

.theory-review-search-panel {
  width: 500px;
  height: 55px;

  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  align-items: center;
  text-align: center;

  color: #000000;
}

.block-students-list-header span {
  width: 40px;
  height: 40px;
  display: flex;
  background: #6D6D6D;
  border-radius: 50%;
}

.block-students-list-header .mdi {
  font-size: 30px;
}

.block-students-list-header span * {
  margin: auto;
}

.block-students-list-header label {
  margin-left: 1rem;
  margin-top: 3px;
}

.ReactModalPortal {
  z-index: 99999;
}

.ReactModal__Overlay {
  z-index: 9999;
}

.theory-review-questions-list-header i {
  cursor: pointer;
}
