.button-container {
  margin-left: 30px;
  width: 44px;
}

.debts-button {
  width: 100%;
  height: 100%;
  background-color: #3988FF;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='17' viewBox='0 0 15 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.3 14.4L11.6 15.6L1.5 8.5L11.6 1.3L13.3 2.5L5.5 8.1L4.9 8.5L5.5 8.9L13.3 14.4Z' fill='white' stroke='white'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #555555;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.debts-button:hover {
  background-color: #337ae5;
}

.debts-button:active {
  background-color: #337ae5;
}
