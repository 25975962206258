.search-input {
  margin: 0;
  z-index: 0 !important;
}

.search-addon {
  padding: 6px 10px !important;
  width: 4rem;
  margin: 0 -4px;
  height: initial !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  color: #404040 !important;
  background-color: #e5e5e5 !important;
}
