.planning-review {
  display: grid;
  grid-template-rows: 1fr;
  height: auto;
}

.planning-review select {
  text-align-last: center;
  text-align: center;
}

#planning-review-modal-close-button {
  margin-left: auto;
  margin-top: 1rem;
  color: black;
}

#planning-review-modal-close-button .mdi:before, .mdi-set {
  font-size: 20px !important;
}

.planning-review .main label {
  font-size: 16px;
  margin: 10px 30px 10px 30px;
  padding: 0;
}

.planning-review .selector-box {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.planning-review .selector-box select {
  width: 255px;
  height: 34px;
}

.selector-box .course-selector {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.selector-box .module-selector {
  margin-left: auto;
  margin-right: 2rem;
}

.planning-review .mentor-select {
  width: 255px;
  height: 34px;
  margin-right: auto;
  margin-left: 2rem;
}

.mentor-selector-box .zoom-select {
  margin-left: auto;
  margin-right: 2rem;
}

.planning-review .mentor-selector-box {
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.planning-review-modal-group-button {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-top: 1px solid #C6C6C6;
}

.planning-review-modal-group-button .wrap-block {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.planning-review .label-box {
  display: flex;
  flex-direction: column;
}

.students {
  display: grid;
  border-top: 1px solid #C6C6C6;
}

.students-header {
  display: flex;
  margin: 10px 0 5px 30px;
  font-size: 16px;
  font-weight: 700;
}

.mentor-page-edit-review-button {
  margin-left: 10px;
}

.student-table {
  display: grid;
  grid-template-columns: 9fr 1fr;
}

.green {
  background-color: rgba(1, 213, 54, 0.1);;
}

.gray {
  background-color: white;
}

.rad {
  background-color: rgba(255, 0, 0, 0.1);;
}

.orange{
  background-color:rgba(206, 158, 0, .1);
}

.student-row {
  padding-left: 15px;
  list-style-type: none;
  margin: 0 0 0 30px;
  border-top: 1px solid #A7A7A7;
  height: auto;
  font-size: 16px;
  font-weight: normal;
}

.student-row-icon {
  border-top: 1px solid #A7A7A7;
  width: 100%;
  text-align: center;
}

.mdi-row {
  font-size: 16px;
}

.check-review {
  color: green;
  font-size: 32px;
}

.mdi-check {
  color: green;
}

.mdi-block-helper {
  color: red;
}
.mdi-account-minus {
  color: #D43F3A;
}

.code-review {
  display: grid;
  grid-template-columns: 6fr 1fr 3fr;
  border-top: 1px solid #C6C6C6;
  align-items: center;
}

.icon-block {
  justify-self: center;
}

.link-block {
  justify-self: center;
}

.mdi-minus-circle-outline {
  color: red;
  font-size: 32px;
}

.link-code-review {
  float: right;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;

  color: #0000FF;
}

.tooltip {
  /*kinda bad solution but it's needed for isolate tooltip*/
  z-index: 9999!important;
}

.disabled-link, .disabled-link:hover, .disabled-link:active, .disabled-link:focus {
  color: grey;
  text-decoration: line-through!important;
}
