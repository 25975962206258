.tasks-list {
  max-height: 30em;
  overflow-y: auto;
  margin: 20px 0;
  border: 1px #9b9b9b;
}

.input-group-addon-custom {
  padding: 6px 10px !important;
  width: 4rem;
  margin: 10px -4px;
  height: initial !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  color: #404040 !important;
  background-color: #e5e5e5 !important;
}
