.EditProfile {
  &__loader {
    display: flex;
    justify-content: center;
  }

  &__header {
    margin-bottom: 24px;

    h1 {
      margin: 0;
    }
  }
}

.EditProfileForm {
  width: 600px;
  background-color: #FFFFFF;
  border-radius: 4px;

  &__container {
    display: flex;
    flex-direction: column;
    padding: 12px;
  }

  &__label {
    display: flex;
    flex-direction: column;
    margin: 0 0 1em;
  }

  &__legend {
    margin: 0 0 .3rem 0
  }

  &__text {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0;
    outline: 0;
    -webkit-appearance: none;
    line-height: 1.2em;
    padding: 0.5em 1em;
    font-size: 1em;
    border: 1px solid rgba(34, 36, 38, .15);
    color: rgba(0, 0, 0, .87);
    border-radius: .3rem;
    box-shadow: 0 0 0 0 transparent inset;
    -webkit-transition: color .1s ease, border-color .1s ease;
    transition: color .1s ease, border-color .1s ease;
  }
}
