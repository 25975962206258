$blue-color: #1695d0;
$dark-blue-color: #28579e;
$base-transition: all 0.2s ease-in-out;

@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin reset {
  margin: 0;
  padding: 0;
}

.btn-modal-disabled {
  display: block;
  position: relative;
  background: none;
  border: none;
  color: #afafaf;
  transition: $base-transition;
  min-width: 80px;
  text-align: center;
}

.btn-modal-open {
  display: block;
  position: relative;
  background: none;
  border: none;
  color: $blue-color;
  transition: $base-transition;
  min-width: 80px;
  text-align: center;
  margin: auto;

  &:hover::after {
    left: 0;
  }

  &:hover::before {
    right: 0;
  }

  &:hover::after,
  &:hover::before {
    color: $dark-blue-color;
    width: 50%;
    transition: $base-transition;
  }

  &:after,
  &:before {
    content:'';
    position: absolute;
    bottom: -3px;
    display: block;
    width: 0;
    height: 2px;
    background-color: $blue-color;
    transition: $base-transition;
  }

  &:after {
    left: 50%;
  }

  &:before {
    right: 50%;
  }

}

.react-confirm-alert-button-group button {
  color: white;

  &.confirm {
    background-color: green;
  }
  &.cancel {
    background-color: red;
  }
}


