.module-collapse-btn:hover {
  background: #6c757d !important;
  cursor: pointer;
  text-decoration: underline;
}

.module-collapse-btn {
  min-height: 7em;
  margin: -1px;
}

.module-card-item {
  width: 100%;
  min-height: 7em;
}

.module-item {
  border: 1px solid #504f4e;
  border-radius: 3px;
}

.module-item-content {
  margin: 1px;
}

.btn-group {
  margin: 10px
}
