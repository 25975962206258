
.carousel-container {
  display: flex;
  margin-right: auto;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}

.carousel-container-wrapper {
  margin-top: 1rem;
}

.carousel-item-container {
  width: 1500px;
  display: flex;
  justify-content: center;
}

.carousel-wrapper {
  display: flex;
  margin: 0 auto;
}

.carousel-area {
  display: flex;
  width: 1000px !important;
  margin: 10px auto 0;
  border-radius: 50%;
  /*border: solid;*/
}

.form-control-area {
  border: 0.5px solid #2e5c94 !important;
}

.carousel-header {
  display: flex;
  justify-content: center;
  margin: 0 auto 1rem;
}

.code-review-btn {
  height: 40px;
  width: 200px;
}
.mentor-comment-open {
  margin-top: 25px;
  background-color: #f3070063;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 80px;
  width: 1000px !important;
  border: 1px SOLID #f30700;
  border-radius: 4px;
  padding: 6px;
}

.mentor-comment-open p {
  white-space: pre-wrap;
  margin-left: 1rem;
}

.mentor-comment-close {
  margin-top: 25px;
  background-color: #01d53673;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
  justify-content: center;
  display: flex;
  flex-direction: row;
  min-height: 80px;
  width: 1000px !important;
  border: 1px SOLID #01d536;
  border-radius: 4px;
  padding: 6px;
}

.mentor-comment-close p {
  white-space: pre-wrap;
  margin-left: 1rem;
}

.mentor-code-review-comment-action i {
  font-size: 20px;
  cursor: pointer;
}

.mentor-code-review-comment-action {
  display: flex;
  flex-direction: column;
  margin: auto 1rem auto auto;
}


.code-review-action-panel {
  width: 1000px;
  margin: 15px auto;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  border-bottom: 2px solid lightgray;
}

.code-review-comment-action {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.code-review-comment-action * {
  margin-right: 1rem;
  cursor: pointer;
}

.code-review-comment-action *:hover {
  text-decoration: underline;
}

.code-review-btn-container {
  margin: 10px auto auto;
  width: 1000px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  z-index: 16;
}

.mentor-code-review-task-type input {
  width: 25px;
  height: 25px;
  margin-left: 0.5rem !important;
}

.mentor-code-review-task-type p {
  margin-top: 0.5rem;
}

.message-text {
  height: 60px!important;
}

.mentor-code-review-task-type {
  display: flex;
  flex-direction: row;
  margin: 5px 20px;
}

.mentor-code-review-comment {
  display: flex;
  flex-direction: row;
}

.compile-result-custom {
  margin: -10px!important;
}
