.analytics-options {
  border-bottom: 1px solid #DDDDDD;
  padding: 15px 0;

  &__date-input {
    min-width: 150px;
    height: 34px;
    padding-right: 4px;
    margin-right: 10px;
    font-size: 16px;
    text-align: center;
  }

  input::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: opacity(0.4);
  }
}
