.ReactModal__Body--open {
    overflow: hidden;
}
.close-modal-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 44px;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    outline: none;
    background: transparent;
    z-index: 10;
    cursor: pointer;
}

.close-modal-button:after {
    content: "×";
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    font: 20px/30px Arial,Helvetica Neue,Helvetica,sans-serif;
    color: #888;
    font-weight: 300;
    text-align: center;
    border-radius: 50%;
    border-width: 0;
    background: #fff;
    transition: background .25s;
    box-sizing: border-box;
    z-index: 2;
}

.popup-head {
    margin-top: 0px;
    margin-bottom: 0px;
}

.modal-popup {
    margin: 44px 0;
}