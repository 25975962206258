.tableHead:hover {
  cursor: pointer;
}

.table {
  table-layout: fixed;
  width: 100%;
  margin: 24px 0;
}

.tableRow {
}

.tableHead {
  text-align: center;

  &:nth-last-child(-n+2), &:first-child {
    vertical-align: middle;
    width: 4em;
    padding: 0;
  }

  span {
    border: none;
    background: none;
    display: inline-block;
    padding: 0;
  }

  svg {
    width: 1em;
    height: 1em;
    fill: white
  }
}

.tableData {
  &:nth-last-child(-n+2), &:first-child {
    vertical-align: middle;
  }

  span {
    border: none;
    background: none;
  }
}

.tableData button {
  padding: 0;
  background-color: transparent;
  border: none;
}

.tableData svg {
  width: 1em;
  height: 1em;
}
