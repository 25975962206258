.page-main-content__review-page {
  height: 100%;
  min-width: 880px;
  background-color: #f6f6f6;
}

.tabs-menu-content {
  padding: 50px 70px;
  min-height: 400px;
  background-color: #fff;
  border: 1px solid #CDCDCD;
}

.spinner-wrapper {
  &_center {
    display: grid;
    place-content: center;
  }
}
