.error-stack {
  color: #e73b3b;
  padding: 2rem;

  &--title {
    margin-bottom: 1rem;
  }

  &--code {
    color: #da1c1c;
  }

  &--message {
    margin: 0;
    padding: 0;
    font-weight: bold;
  }
}

.quote-wrap--error {
  background-image: linear-gradient(-38deg, #e73b3b 0%, #e93333 100%);
}