
.taskAnswers {

  margin-top: 30px;
  margin-bottom: 20px;

  .rightAnswersBlock {
    margin-top: 20px;
    margin-bottom: 20px;

    .checkboxItem {
      margin-left: 20px;

      input {
        margin-left: 2px;
      }
    }

  }
}

.addButton {
  display: flex;
  justify-content: center;
  text-decoration: underline;
}
