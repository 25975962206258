* {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato', sans-serif !important;
  margin: 0;
  padding: 0;
}

//body {
//  all: initial !important;
//  min-width: 320px;
//  background-color: #F6F6F6;
//  font-family: 'Lato', sans-serif;
//  font-size: 14px;
//}

ul {
  list-style: none;
}
