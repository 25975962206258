.select {
  display: inline-block;
  min-width: 250px;
  margin-bottom: 24px;
  padding: 4px 10px;
  font-size: 16px;
  color: #a1a1a1;
  border: 1px solid #787878;
  border-radius: 10px;
  outline-offset: 0;
  outline-color: #000;

  &:focus {
    outline-style: dotted;
  }
}
