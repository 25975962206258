.meet-page {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #E5E5E5;
  min-height: 100vh;
  margin: 0;

  display: grid;
  grid-template-rows: auto auto 1fr auto;
}

.meet-page__header {
  background-color:#AFAFAF;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  font-size: 22px;

}

.meet-page__header .mdi {
  font-size: 36px;
  color: #236D6D;
}

.meet-page__content {
  overflow-y: auto;
  margin: 0 32px;

}

.meet-page__controls {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 16px 32px;
}

.conf-link.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 850px;
}

.meet-page__controls button,.meet-page__footer button {
 min-width: 225px;
}

.meet-page-table {

}

.meet-page-table__head {
  position: sticky;
  top: -1px;
  background-color: #E5E5E5;
  z-index: 10;
}

.meet-page-table__th {
  text-align: center;
}

.meet-page-table .meet-page-table__td {
  vertical-align: middle;
  white-space: pre-wrap;
}

.meet-page-table__status {
  white-space: nowrap;
}

.meet-page-table__action-btn {
  min-width: auto;
}

.meet-page__footer {
  display: flex;
  justify-content: end;
  gap: 16px;
  padding: 16px 32px;
}

.meet-status {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 3px solid;
  border-radius: 50%;
  margin-right: 2px;
}

.meet-status--missed {
  border-color: #DC3545;
  background-color: #F8D7DA;

}

.meet-status--visited {
  border-color: #28A745;
  background-color: #C3E6CB;
}

.meet-status--not-selected {
  border-color: #6C757D;
  background-color: #C4C4C4;
}

.meet-page-table__status-btn{
  background: none;
  border: 1px solid;
  width: 40px;
  height: 32px;
}
.meet-page-table__status-btn--visit{
  border-color: #28A745;
  color: #28A745;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.meet-page-table__status-btn--visit.meet-page-table__status-btn--active {
  color: #FFF;
  background-color: #28A745;
}

.meet-page-table__status-btn--miss {
  border-color: #DC3545;
  color: #DC3545;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.meet-page-table__status-btn--miss.meet-page-table__status-btn--active {
  color: #FFF;
  background-color: #DC3545;
}


.status-select__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 4px;
}

.status-select__label {
  margin: 0;
}

.status-select {
  height: 38px;
}
