.inner-addon {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 87%;
  margin-left: auto;
  right: 2%;
}

.inner-addon * {
  margin-left: auto;
  margin-right: 6px;
}

/* style icon */
.inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
  margin: 9px 0;
}

/* align icon */
.left-addon .glyphicon {
  left: 0px;
}

.right-addon .glyphicon {
  right: 0px;
}

/* add padding  */
.left-addon input {
  padding-left: 30px;
}

.right-addon input {
  padding-right: 30px;
}

.theory-review-search-input {
  width: 240px !important;
  height: 34px !important;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #A4A4A4;
}

.theory-review-select-question-type {
  width: 150px !important;
  height: 34px !important;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}
