
.btnHelp {
  position: absolute;
  left: 312px;
  background: #3988FF;
  border: 1px solid #555555;
  box-sizing: border-box;
  border-radius: 0 4px 4px 0;
}

.btnClose {
  position: relative;  
  background: rgba(255, 0, 0, 1);
  border: 1px solid #555555;
  box-sizing: border-box;
  border-radius: 0;
}

// .btnCloseNotActive {
//   background: rgba(255, 0, 0, 1);
//   // background: rgba(255, 0, 0, 0.1);
// }

.btnDone {
  position: relative;
  background: rgba(1, 213, 54, 1);
  border: 1px solid #555555;
  box-sizing: border-box;
  border-radius: 4px 0 0 4px;
}

// .btnDoneNotActive {
//   // background: rgba(1, 213, 54, 1);
//   background: #555555;
//   // background: #555555, rgba(1, 213, 54, 0.1) !important;
// }


.statusBtnGroup{
  width: 110px;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  border-radius: 4px;
  border: transparent;
}

.btnStatus{
  position: absolute;
  left:218px;
  width: 90px !important;
  background: #3988FF;
  border: 1px solid #555555;
  box-sizing: border-box;
  border-radius: 4px 0  0 4px;
  color: white;
}

.btnStatus:hover {
  font-weight: bold;
  box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, .1), -2px -2px 5px 0 rgba(0, 0, 0, .1), 2px 2px 5px 0 rgba(0, 0, 0, .1), -2px 2px 5px 0 rgba(0, 0, 0, .1);
}

.btnStatusDone{
  background: rgba(1, 213, 54, 1);
}
.btnStatusFailed{
  background: rgba(255, 0, 0, 1);
}
.btnStatusMiss{
  background:  #CE9E00;
}

.btnMiss{
  position: relative;
  background: #CE9E00;
  border: 1px solid #555555;
  box-sizing: border-box;
  border-radius:0 4px 4px 0;

}

.btnReview {
  width: 32px;
  height: 32px;
  top: 6px;
}

.btnReview i {
  color: white;
}

.btnReview:hover i {
  font-size: 18px;
  font-weight: bold;
}

.btnReview:hover {
  box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, .1), -2px -2px 5px 0 rgba(0, 0, 0, .1), 2px 2px 5px 0 rgba(0, 0, 0, .1), -2px 2px 5px 0 rgba(0, 0, 0, .1);
}
.btnNotActive {
  background: #555555;
}
.btnNotActive:hover {
  box-shadow: none;
  cursor: auto;
}
.btnNotActive:hover i {
  font-size: inherit !important;
  font-weight: inherit !important;
}