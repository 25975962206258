.table {
  width: auto;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: separate;

  margin-bottom: 30px;

  .head  {
    text-align: center;
    background: #CDDFFF;
    padding: 12px 20px;
    border-top: 1px solid gray;
    white-space: pre-line;

    &:first-child {
      padding-left: 40px;
      border-top-left-radius: 10px;
      border-top: 1px solid gray;
      border-left: 1px solid gray;
    }

    &:last-child {
      padding-right: 40px;
      border-top-right-radius: 10px;
      border-top: 1px solid gray;
      border-right: 1px solid gray;
    }
  }

  .row {
    &:nth-child(even) {
      background-color: #E7E7E7;
    }

    &:last-child {
      td {
        border-bottom: 1px solid gray;
      }
    }
  }

  .col {
    padding: 12px 20px;
    max-width: 600px;

    &:first-child {
      padding-left: 40px;
      border-left: 1px solid gray;
    }

    &:last-child {
      padding-right: 40px;
      border-right: 1px solid gray;
    }
  }

  .row:last-child .col:first-child {
    border-bottom-left-radius: 10px;
    border-bottom: 1px solid gray;
    border-left: 1px solid gray;
  }

  .row:last-child .col:last-child {
    border-bottom-right-radius: 10px;
    border-bottom: 1px solid gray;
    border-right: 1px solid gray;
  }

  &TabsMenuContent {
    margin-bottom: 30px;
  }

  a {
    text-decoration: underline;
  }

  .showMore {
    background: none;
    border: none;
    text-decoration: underline;
    color: #3988FF;

    &:hover {
      color: #2d6ccc;
    }

    &:focus {
      color: #2d6ccc;
    }
  }

  tbody:only-child {
    .row {
      &:nth-child(odd) {
        background-color: #E7E7E7;
      }
      &:nth-child(even) {
        background-color: #F3F3F3;
      }

      &:first-child {
        td {
          border-top: 1px solid gray;
        }

        & .col:first-child {
          border-top-left-radius: 10px;
        }

        & .col:last-child {
          border-top-right-radius: 10px;
        }
      }

      &:last-child {
        td {
          border-bottom: 1px solid gray;
        }
      }
    }
  }
}

.w100 {
  width: 100%;
}
