.students-list {
  counter-reset: li;
  margin-bottom: 0;
  padding: 0;
}

.students-header {
  justify-content: start;
}

.selected-student {
  background-color: lightskyblue !important;
}

ol.students-list {
  counter-reset: students-list;
}

.unselected-student {
  background-color: rgba(211, 211, 211, 0.5);
}

.unselected-student:hover {
  background-color: rgba(138, 138, 138, 0.5);
}

.passed-student {
  box-shadow: 2px -2px 5px 0 rgba(115, 180, 126, .6),
  -2px -2px 5px 0 rgba(115, 180, 126, .6),
  2px 2px 5px 0 rgba(115, 180, 126, .6),
  -2px 2px 5px 0 rgba(115, 180, 126, .6) !important;
}
.missed-student {
  box-shadow: 2px -2px 5px 0  rgba(206, 158, 0, .6),
  -2px -2px 5px 0 rgba(206, 158, 0, .6),
  2px 2px 5px 0 rgba(206, 158, 0, .6),
  -2px 2px 5px 0 rgba(206, 158, 0, .6) !important;
}

ol.students-list > li::before {
  content: counter(students-list);
  counter-increment: students-list;
}


.student-list-container-wrapper ol > li {
  height: 44px !important;
}

.link-to-channels-input {
  margin-left: 3px;
  margin-right: 3px;
  padding: 6px 3px;
  width: 280px;
  height: 34px !important;
  left: 10px;
  top: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.42857143;
  align-items: center;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.reference-to-slack-channel {
  padding-bottom: 10px;
}

.zoom-account {
  padding-top: 10px;
}

.btn.link-to-channels-button {
  background-color: #3988FF;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  width: 81px;
  height: 34px !important;
  right: 0px;
  top: calc(50% - 34px / 2);
}

.btn.link-to-channels-button:hover {
  background-color: #3479e3;
  color: #FFFFFF;
}

.wrapper-form-link-to-channels {
  padding-top: 45px;
}

.student-list-container-wrapper-row {
  display: flex;
  align-items: stretch;
}

.button-create-meeting {
  background-color: #4ac469;
  color: #FFFFFF;
}
