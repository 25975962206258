@font-face {
  font-family: 'Lato';
  src: url('/fonts/Lato-LightItalic.eot');
  src: url('/fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Lato-LightItalic.woff2') format('woff2'),
  url('/fonts/Lato-LightItalic.woff') format('woff'),
  url('/fonts/Lato-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/fonts/Lato-Italic.eot');
  src: url('/fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Lato-Italic.woff2') format('woff2'),
  url('/fonts/Lato-Italic.woff') format('woff'),
  url('/fonts/Lato-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/fonts/Lato-Bold.eot');
  src: url('/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Lato-Bold.woff2') format('woff2'),
  url('/fonts/Lato-Bold.woff') format('woff'),
  url('/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/fonts/Lato-Light.eot');
  src: url('/fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Lato-Light.woff2') format('woff2'),
  url('/fonts/Lato-Light.woff') format('woff'),
  url('/fonts/Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/fonts/Lato-Semibold.eot');
  src: url('/fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Lato-Semibold.woff2') format('woff2'),
  url('/fonts/Lato-Semibold.woff') format('woff'),
  url('/fonts/Lato-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/fonts/Lato-Regular.eot');
  src: url('/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Lato-Regular.woff2') format('woff2'),
  url('/fonts/Lato-Regular.woff') format('woff'),
  url('/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/fonts/Lato-SemiboldItalic.eot');
  src: url('/fonts/Lato-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Lato-SemiboldItalic.woff2') format('woff2'),
  url('/fonts/Lato-SemiboldItalic.woff') format('woff'),
  url('/fonts/Lato-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/fonts/Lato-BoldItalic.eot');
  src: url('/fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Lato-BoldItalic.woff2') format('woff2'),
  url('/fonts/Lato-BoldItalic.woff') format('woff'),
  url('/fonts/Lato-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
