.filters {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.label {
  display: flex;
  gap: 0.5em;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.label:not(:last-child) {
  margin-right: 1.5em;
}

.inputWrapper {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.input {
  max-width: 11em;
  margin: 0;
}

.modalWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.modal {
  background-color: #fff;
  border: 1px solid #ABABAB;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  max-width: 500px;
}

.overlayConfim {
  z-index: 99999;

  .ok {
    background-color: #198754;
  }

  .cancel {
    background-color: #dc3545;
  }
}

.spinner {
  div {
    margin: 0 auto
  }
}
