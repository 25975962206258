.questionTextInput {
  display: flex;
  align-items: center;

  &__label {
    margin-right: 20px;
  }
}

.answersWrap {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.answer {
  display: flex;
  align-items: center;

  input[type="checkbox"].answer__checkbox {
    margin-right: 8px;
  }
}

.inputWithIcon {
  position: relative;
  width: 100%;

  &__input {
    padding-right: 46px !important;

    &:focus + .inputWithIcon__icon {
      border: 1px solid rgb(102, 175, 233) !important;
    }
  }

  &__icon {
    position: absolute;
    top: 2px;
    right: 2px;
    border-radius: 0 4px 4px 0 !important;
  }
}

.iconButton {
  padding: 0 6px !important;
  background-color: #E5E5E5;
  border: 1px solid #cccccc !important;

  i {
    font-size: 21px;
  }
}

.linkButton {
  display: block !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
