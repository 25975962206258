#add-review-header {
  margin: 1rem auto auto;
}

.add-review-page-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.review-options {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

.add-review-tables {
  display: flex;
  flex-direction: row;
}

