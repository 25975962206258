.page {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 50px 1fr;
  min-height: 100vh;
  height: 100%;
}

.main {
  padding: 40px 50px;
  background-color: #f6f6f6;
  overflow-x: auto;
  text-align: left;
}

.header {
  grid-column: 1 / -1;
}

.sidebar {

}
