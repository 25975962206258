.answer-data {
  margin-top: 0!important;
}

.label-answer-data {
  font-size: 14px;
  margin: 10px;
  color: gray;
}

.btn-left-rounded {
  border-top-left-radius: 6px!important;
  border-bottom-left-radius: 6px!important;
}
