.student-question {
  display: flex;
  justify-content: center;
  width: 40%;
  margin-top: 1rem;
  height: 100%;
  overflow-y: auto;
}

.student-question-list {
  display: flex;
  justify-content: center;
  word-break: break-all;
}

.student-question-list p {
  white-space: pre;
  text-align: left;
  word-break: break-all;
  overflow-x: auto;
}

.student-question-list {
  margin-right: 1rem;
  counter-reset: li;
  list-style: none;
  font: 18px "Trebuchet MS", "Lucida Sans";
  padding: 0;
  text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
}

.student-debts {
  height: fit-content;
  margin-right: 4rem;
  min-width: 400px;
}

