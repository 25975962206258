.planning-review-modal {
  display: contents;
  flex-direction: column;
  width: 650px;
  height: 410px;
  font-style: normal;
  font-weight: normal;
}

.planning-review-modal select {
  text-align-last: center;
  text-align: center;
}

.planning-review-modal .selector-box .planning-review-checkbox {
  width: 20px;
}

.planning-review-modal header {
  display: flex;
  flex-direction: row;
  height: 75px;
  background: #e4e4e4;
  border: 1px solid #e4e4e4;
}

#planning-review-modal-header {
  line-height: 19px;
  margin: auto;
}

#planning-review-modal-close-button {
  margin-left: auto;
  margin-top: 1rem;
  color: black;
}

#planning-review-modal-close-button .mdi:before, .mdi-set {
  font-size: 20px !important;
}

.planning-review-modal main {
  flex: auto;
  background-color: white;
}

.planning-review-modal main label {
  font-size: 16px;
  padding: 15px;
}

.planning-review-modal .selector-box {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.planning-review-modal .selector-box select {
  width: 255px;
  height: 34px;
}

.selector-box .course-selector {
  margin-right: auto;
  margin-left: 2rem;
}

.selector-box .module-selector {
  margin-left: auto;
  margin-right: 2rem;
}

.planning-review-modal .mentor-select {
  width: 255px;
  height: 34px;
  margin-right: auto;
  margin-left: 2rem;
}

.mentor-selector-box .zoom-select {
  margin-left: auto;
  margin-right: 2rem;
}

.planning-review-modal .mentor-selector-box {
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.planning-review-modal footer {
  height: 60px;
}

.planning-review-modal-group-button {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.planning-review-modal-group-button .wrap-block {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.planning-review-modal-group-button .create-button {
  width: 150px;
  height: 40px;
  background: rgba(40, 167, 69, 0.65);
  border: 1px solid #4cae4c;
  border-radius: 10px;
  margin-right: 2rem;
  color: white;
}

.planning-review-modal-group-button .back-button {
    width: 150px;
    height: 40px;
    background: #F0AD4E;
    border: 1px solid #EEA236;
    border-radius: 10px;
    color: white;
}

.planning-review-modal-group-button .cancel-button {
  width: 150px;
  height: 40px;
  background: #F0AD4E;
  border: 1px solid #EEA236;
  border-radius: 10px;
  color: white;
}

.planning-review-modal-group-button .delete-button {
  width: 150px;
  height: 40px;
  background: #D9534F;
  border: 1px solid #D43F3A;
  border-radius: 10px;
  margin-right: 2rem;
  color: white;
}

.planning-review-modal-group-button .save-button {
  width: 150px;
  height: 40px;
  background: #5cb85c;
  border: 1px solid #4cae4c;
  border-radius: 10px;
  margin-right: 2rem;
  color: white;
}

.planning-review-modal .label-box {
  display: flex;
  flex-direction: column;
}

.students-rows {
  width: 100%;
}

.students-rows > ul {
  padding: 0; /* Убираем поля */
  margin-left: 3px;
}

.green {
  background-color: rgba(1, 213, 54, 0.1);;
}

.gray {
  background-color: white;
}

.rad {
  background-color: rgba(255, 0, 0, 0.1);;
}

.stud-row {
  padding: 3px 15px;
  list-style-type: none;
  margin-top: 0px;
  border-bottom: 1px solid #A7A7A7;;
  height: auto;
  font-size: 16px;
  font-weight: normal;
}

.mdi-16 {
  float: right;
  font-size: 16px;
}

.mdi-check {
  color: green;
}

.mdi-block-helper {
  color: red;
}

.zoom-link {
  margin-left: 5px;
}
