.btn-directions {
  text-align: center;
  flex: 1;
  background: transparent !important;
  color: #3988FF;
  transition: all .2s ease-in;
  border: none;
}
.btn-directions:active {
  border: none;
}

.btn-directions:hover {
  color: #004fc5 !important;
}

.modal-directions {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.direction-modal--header {
  width: 100%;
  height: 50px;
  background-color: #E4E4E4;
  text-align: center;
  position: relative;
  display: grid;
  place-items: center;
}

.direction-modal--title {
  font-weight: bold;
  font-size: 1.5rem;
}

.direction-modal--btn {
  position: absolute;
  top: 25%;
  right: 0;
  background-color: transparent ;
  transform: translate(0, -25%);
  border: none;
}

.direction-modal--btn > span {
  font-size: 1.7rem;
}

.direction-modal--main {
  width: 100%;
  padding: 2rem;
}

.mr-1{
  margin-right: 1rem ;
}

.content {
  width: 100%;
}

.input-group-addon-custom {
  margin-left: 0;
}

.direction-modal--controls {
  margin-bottom: 2rem;
}

.modal-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
  position: relative;
}

.modal-actions-inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-modal {
  color: aliceblue;
  border:none;
  border-radius: 5px;
  padding: 10px 20px;
}

.btn-modal--success {
  background-color: #5CB85C;
}

.btn-modal--decline {
  background-color: #F0AD4E;
  min-width: 120px;
}

.btn-modal--add {
  width: 100%;
  margin-top: 30px;
  background-color: #5CB85C;
}

.btn-modal--back {
  background-color: #F0AD4E;
  width: 150px;
  max-width: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.list-wrapper {
  max-height: 500px;
  overflow-y: auto;
}

.modal-info {
  margin-top: 20px;
  text-align: center;
}
