.footer{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  background: #fff;
  border-top: 1px solid #C6C6C6;
  box-sizing: border-box;
}

