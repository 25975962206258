.meet-plan-modal {
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px 0;
  }
  &__label {
    display: block;
    margin-top: .5rem;
    margin-bottom: 1rem;
  }

  &__text {
    margin-left: .5rem;
  }

  &__date {
    display: flex;
    align-items: center;
    gap: .5rem;
  }

  &__option {
    margin-left: 1.3rem;
  }

  &__input-date {
    font-size: .875rem !important;
    width: auto !important;
  }

  &__time {
    display: flex;
    align-items: center;
    gap: .5rem;
    margin-top: 1rem;
  }

  &__input-time {
    min-width: 100px;
    font-weight: normal;
    font-size: .875rem !important;
    padding: .375rem .75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  &__link {
    display: flex;
    max-height: 38px;
    margin: auto .25rem auto 0;
    align-items: center;
    justify-content: center;
    color: white;
    background: #007bff;
    display: inline-block;
    font-weight: 400;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:hover {
      color: white;
      background: #026de0;
    }
  }
}
