@import "../constants";

.select-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    &__month {
      font-size: 20px;
    }
  
    &__year {
      margin-left: 10px;
      font-size: 18px;
    }
  
    &__card {
      padding: 5px;
      font-size: 16px;
    }
  }
  
  .select-date-wrapper {
    max-width: 200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .report-select-date {
    position: relative;
  
    &__btn {
      color: $mainBlue;
      background: transparent;
      border-radius: 5px;
      width: 2rem;
      height: 2rem;
      border: 2px solid currentColor;
  
      &:disabled {
        color: $firsGray;
      }
    }
  }
  