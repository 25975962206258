.students-mentor-block-content {
  margin: 1px;
}

.students-mentor-block {
  width: 80%;
  float: left;
}

.students-free-block {
  width: 80%;
  margin-left: auto;
}

.students-free-block-content {
  margin: 1px;
}

.header-students-mentor {
  text-align: center;
  font-size: 24px;
}

.header-free-students-mentor {
  text-align: center;
  font-size: 24px;
}

.direction-back-button {
  margin-top: 20px;
  width: 200px;
  height: 34px;
  margin-right: 2rem;
}
