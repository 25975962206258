.code-review-page-header h3 {
  display: flex;
  margin-right: auto;
  margin-left: 1rem;
}

.student-code-block {
  display: flex;
  margin-right: auto;
  height: 100%;
  width: 65%;
  flex-direction: column;
}

.code-review-page-header {
  flex: 1 1;
  max-height: 100px;
  display: flex;
  flex-direction: row;
}

.notification-modal-overlay {
  z-index: 99999;
}

.task-description {
  height: 700px;
  padding: 1rem;
  overflow-y: auto;
}

.code-review-info {
  margin-top: 1rem;
}
