.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.6s;
}
.loader-inner {
  border: 7px solid rgba(17, 91, 202, 0.6);
  border-left-color: #3a95e4;
  border-radius: 50%;
  animation: Loader 1.3s linear infinite;
}

@keyframes Loader {
  100% { 
    transform: rotate(360deg);
  }
}