.tab-menu {
  margin-top: 50px;

  &__tabs-list {
    display: flex;
    margin-bottom: 0;
    margin-right: -10px;
  }

  &__tab-item {
    flex: 0 1 250px;
    margin-right: 10px;
    text-align: center;
  }

  &__tab-link {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 0 15px;
    background-color: #E0E0E0;
    border-radius: 10px 10px 0px 0px;
    font-size: 16px;
    line-height: 55px;
    color: #555;
    white-space: nowrap;
    transition: all 0.2s linear;

    &:hover, &:focus {
      background-color: #c9c9c9;
    }

    &_active {
      background-color: #B4CEFF;
      font-weight: bold;
      font-size: 18px;
      color: #656565;

      &:focus, &:hover {
        background-color: #B4CEFF;
        color: #656565;
      }
    }
  }
}
