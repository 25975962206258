.lesson-description {
  all: initial;
  font: inherit;
  /*font-size: 1.0rem;*/
  font-weight: normal;
  color: inherit;
}

.lesson-description p {
  text-align: justify;
  /*text-indent: 20px;*/
}

.lesson-description pre {
  font-size: initial;
  max-height: initial;
  text-indent: initial;
}

/* .form-control Ломает стили react-boostrap */

/* .form-control {
  margin-top: 10px;
  margin-bottom: 10px;
} */

.plyr {
  text-align: center;
  width: 60%;margin-left: auto;
  margin-right: auto;
}
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  background: #F6F6F6 !important;
  color: #404040 !important;
  font-family: 'Lato', sans-serif !important;
  min-width: 320px !important;
  font-display: swap;
}

::-webkit-input-placeholder {
  color: #A2A2A2;
}

::-moz-placeholder {
  color: #A2A2A2;
}

:-ms-input-placeholder {
  color: #A2A2A2;
}

:-moz-placeholder {
  color: #A2A2A2;
}

a:hover,
a:focus,
a:active,
button:hover,
button:focus,
button:active,
input:focus,
input:active,
input:hover {
  outline: none !important;
  text-decoration: none !important;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#root[aria-hidden="true"] {
  filter: blur(3px);
}

.profile-page-wrap,
.all-course-wrap,
.detail-course-wrap,
.step-bottom-wrapper,
.edit-profile-wrapper {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}

.datepicker--day-name {
  color: #9787f7;
}

.datepicker--cell.-selected-,
.datepicker--cell.-selected-.-current- {
  background: #6554C9 !important;
}

p {
  margin: 0px 0px 20px 0px;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.header .top-header {
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(228, 228, 228, 0.4);
  height: 64px;
  position: fixed;
  overflow: initial;
  left: 0px;
  right: 0px;
  top: 0px;
  width: 100%;
  z-index: 10;
}

.header .top-header .logo {
  float: left;
  display: inline-block;
  margin-top: 17px;
  margin-right: 64px;
}

.header .top-header .top-nav {
  float: left;
  padding: 0px;
  list-style: none;
}

.header .top-header .top-nav li {
  float: left;
}

.header .top-header .top-nav li a {
  border-bottom: 3px solid #ffffff;
  color: #404040;
  font-size: 18px;
  display: block;
  padding: 0px 20px;
  padding-top: 17px;
  height: 64px;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.header .top-header .pageHostLabel {
  margin-left: 70px;
  color: #404040;
  font-size: 18px;
}

.header .top-header .top-nav li a:hover {
  border-bottom: 3px solid #c0d6ff;
}

.header .top-header .top-nav li.active a {
  color: #4B8AFF;
  border-bottom: 3px solid #4B8AFF;
}

.header .top-header .header-right-wrap {
  float: right;
  position: relative;
}

.header .top-header .header-right-wrap .notify-wrap {
  float: left;
  position: relative;
}

.header .top-header .header-right-wrap .notify-btn {
  padding: 0px 6px;
  height: 64px;
  width: 52px;
  line-height: 1;
  padding-top: 11px;
  position: relative;
}

.header .top-header .header-right-wrap .notify-btn i {
  color: #4B8AFF;
  font-size: 38px;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.header .top-header .header-right-wrap .notify-btn .notify-counter {
  background: #fff;
  border: 1px solid #4B8AFF;
  border-radius: 50%;
  position: absolute;
  color: #4B8AFF;
  display: block;
  font-size: 14px;
  font-weight: 600;
  height: 20px;
  width: 20px;
  right: 6px;
  top: 30px;
  text-align: center;
  line-height: 18px;
}

.header .top-header .header-right-wrap .notify-btn:hover {
  cursor: pointer;
}

.header .top-header .header-right-wrap .notify-btn:hover i {
  color: #3b80ff;
}

.header .top-header .header-right-wrap .notify-message-wrap {
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 0 0 10px 0 rgba(116, 116, 116, 0.12);
  border-radius: 4px;
  display: block;
  padding: 28px 22px;
  position: absolute;
  right: -6px;
  top: 70px;
  width: 452px;
  z-index: 8;
}

.header .top-header .header-right-wrap .notify-message-wrap .notify-message-head {
  color: #404040;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
}

.header .top-header .header-right-wrap .notify-message-wrap .notify-message {
  border-bottom: 1px solid #E8E6EF;
  display: block;
  margin-bottom: 14px;
  padding-bottom: 14px;
  position: relative;
}

.header .top-header .header-right-wrap .notify-message-wrap .notify-message .notify-message-pic {
  background: #8db5ff;
  border-radius: 50%;
  float: left;
  margin-right: 12px;
  margin-top: 3px;
  display: block;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
}

.header .top-header .header-right-wrap .notify-message-wrap .notify-message .notify-message-pic i {
  color: #fff;
  font-size: 24px;
}

.header .top-header .header-right-wrap .notify-message-wrap .notify-message .notify-text-wrap .message-title {
  color: #404040;
  font-size: 16px;
}

.header .top-header .header-right-wrap .notify-message-wrap .notify-message .notify-text-wrap .message-date {
  color: #D2D2D2;
  font-size: 16px;
}

.header .top-header .header-right-wrap .notify-message-wrap .notify-message:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.header .top-header .header-right-wrap .notify-message-wrap:before {
  content: "";
  display: block;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 0 0 10px 0 rgba(116, 116, 116, 0.12);
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
  position: absolute;
  top: -10px;
  right: 22px;
}

.header .top-header .header-right-wrap .notify-message-wrap:after {
  border-radius: 0px 4px 0px 0px;
  content: "";
  display: block;
  background: #fff;
  width: 64px;
  height: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.header .top-header .header-right-wrap .profile-menu-wrap {
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 0 0 10px 0 rgba(116, 116, 116, 0.12);
  border-radius: 4px;
  display: block;
  padding: 6px 0px;
  padding-bottom: 4px;
  position: absolute;
  right: -8px;
  top: 70px;
  width: 254px;
  z-index: 8;
}

.header .top-header .header-right-wrap .profile-menu-wrap ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.header .top-header .header-right-wrap .profile-menu-wrap ul li a {
  color: #404040;
  font-size: 16px;
  display: block;
  padding: 14px 22px;
  position: relative;
  z-index: 10;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.header .top-header .header-right-wrap .profile-menu-wrap ul li a:hover {
  background: #e8f1ff;
}

.header .top-header .header-right-wrap .profile-menu-wrap ul li:first-child a {
  border-bottom: 1px solid #E8E6EF;
  padding-bottom: 14px;
}

.header .top-header .header-right-wrap .profile-menu-wrap ul li:last-child a {
  border-top: 1px solid #E8E6EF;
  padding-top: 14px;
}

.header .top-header .header-right-wrap .profile-menu-wrap:before {
  content: "";
  display: block;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 0 0 10px 0 rgba(116, 116, 116, 0.12);
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
  position: absolute;
  top: -10px;
  right: 17px;
}

.header .top-header .header-right-wrap .profile-menu-wrap:after {
  border-radius: 0px 4px 0px 0px;
  content: "";
  display: block;
  background: #fff;
  width: 42px;
  height: 20px;
  position: absolute;
  right: 8px;
  top: 0px;
}

.header .top-header .header-right-wrap .profile-icn {
  border: 2px solid #4B8AFF;
  border-radius: 50%;
  float: left;
  height: 42px;
  width: 42px;
  margin-top: 10px;
  margin-left: 20px;
  overflow: hidden;
}

.header .top-header .header-right-wrap .profile-icn img {
  border-radius: 50%;
  height: 38px;
  width: 38px;
}

.header .top-header .header-right-wrap .profile-icn:hover {
  cursor: pointer;
}

.header .top-header .header-right-wrap .mobile-menu-btn {
  display: none;
}

.middle-header {
  background: #fff;
  margin-top: 64px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.middle-header .page-title-course-pic {
  border: 1px solid #E8E8E8;
  border-radius: 9px;
  float: left;
  width: 132px;
  height: 82px;
  margin-right: 25px;
  overflow: hidden;
}

.middle-header .page-title-course-pic img {
  object-fit: scale-down;
  /*max-width: 130px;*/
  /*max-height: 82px;*/
  height: 100%;
  width: 100%;
}

.middle-header .page-title-wrap .breadcrumbs .link span {
  color: #C3C3C3;
  font-size: 20px;
}

.middle-header .page-title-wrap .breadcrumbs .link a {
  color: #C3C3C3;
  font-size: 20px;
}

.middle-header .page-title-wrap .breadcrumbs .link a:hover {
  text-decoration: underline;
}

.middle-header .page-title-wrap .breadcrumbs .link:after {
  color: #C3C3C3;
  content: "/";
  font-size: 20px;
  margin: 0px 8px;
  position: relative;
  top: 1px;
}

.middle-header .page-title-wrap .page-title {
  color: #404040;
  font-size: 44px;
  font-weight: 700;
  line-height: 1.2;
}

.middle-header.lesson-step .page-title-course-pic {
  height: 142px;
  width: 224px;
}

.middle-header.lesson-step .page-title-course-pic img {
  max-height: 142px;
  max-width: 224px;
}

.middle-header.lesson-step .page-title-wrap {
  overflow: hidden;
}

.middle-header.lesson-step .page-title-wrap .page-title {
  display: inline-block;
  margin-right: 25px;
}

.middle-header.lesson-step .page-title-wrap .page-title-progress {
  color: #8A8A8A;
  font-size: 20px;
  margin-top: 16px;
  display: inline-block;
  vertical-align: top;
}

.middle-header.lesson-step .lesson-navigation {
  font-size: 0px;
  list-style: none;
  padding: 0px;
  margin: 0px;
  margin-top: 16px;
}

.middle-header.lesson-step .lesson-navigation a {
  background: #DEDEDE;
  border-radius: 50%;
  color: #9f9f9f;
  display: inline-block;
  font-size: 25px;
  text-align: center;
  margin-right: 10px;
  line-height: 36px;
  overflow: hidden;
  height: 38px;
  width: 38px;
  opacity: 0.5;
  margin-top: 4px;
  vertical-align: top;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.middle-header.lesson-step .lesson-navigation a:hover {
  opacity: 0.8;
}

.middle-header.lesson-step .lesson-navigation a.completed {
  background: #01D536;
  color: #fff;
}

.middle-header.lesson-step .lesson-navigation a.right {
  background: #01D536;
  color: #fff;
}

.middle-header.lesson-step .lesson-navigation a.completed {
  background: #01D536;
  color: #fff;
}

.middle-header.lesson-step .lesson-navigation a.onCheck {
  background: #ffcb00;
  color: #fff;
}

.middle-header.lesson-step .lesson-navigation a.current-onCheck {
  background: #ffcb00;
  color: #fff;
  font-size: 34px;
  height: 46px;
  line-height: 45px;
  width: 46px;
  opacity: 1;
  margin-top: 0px;
}

.middle-header.lesson-step .lesson-navigation a.unRight {
  background: #e52528;
  color: #fff;
}

.middle-header.lesson-step .lesson-navigation a.current-unRight {
  background: #e52528;
  color: #fff;
  font-size: 34px;
  height: 46px;
  line-height: 45px;
  width: 46px;
  opacity: 1;
  margin-top: 0px;
}

.middle-header.lesson-step .lesson-navigation a.current-completed {
  background: #01D536;
  color: #fff;
  font-size: 34px;
  height: 46px;
  line-height: 45px;
  width: 46px;
  opacity: 1;
  margin-top: 0px;
}

.middle-header.lesson-step .lesson-navigation a.current-right {
  background: #01D536;
  color: #fff;
  font-size: 34px;
  height: 46px;
  line-height: 45px;
  width: 46px;
  opacity: 1;
  margin-top: 0px;
}

.middle-header.lesson-step .lesson-navigation a.current-completed {
  background: #01D536;
  color: #fff;
  font-size: 34px;
  height: 46px;
  line-height: 45px;
  width: 46px;
  opacity: 1;
  margin-top: 0px;
}

.middle-header.lesson-step .lesson-navigation a.current {
  background: #679FFF;
  color: #fff;
  font-size: 34px;
  height: 46px;
  line-height: 45px;
  width: 46px;
  opacity: 1;
  margin-top: 0px;
}

.middle-header.all-courses {
  padding-bottom: 40px;
}

.middle-header.all-courses .page-title-wrap .page-title {
  font-size: 44px;
}

.quote-wrap {
  background-image: linear-gradient(-38deg, #4b8dff 0%, #4085fc 100%);
  border-radius: 10px;
  padding: 30px 40px;

  position: relative;
  margin: 50px auto;
  width: 1290px !important;
}

.quote-wrap .quote-text {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}

.quote-wrap .quote-author {
  color: #fff;
  font-style: italic;
  font-size: 20px;
  font-weight: 600;
}

.quote-wrap:after {
  background: url("/images/quotes.svg") no-repeat center;
  content: "";
  display: block;
  position: absolute;
  right: 25px;
  top: 20px;
  height: 66px;
  width: 94px;
}

.courses-wrap .courses-title {
  color: #404040;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 35px;
}

.courses-wrap .course-block {
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(59, 63, 98, 0.08);
  border-radius: 10px;
  padding-bottom: 30px;
  display: block;
  position: relative;
  margin-bottom: 30px;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.courses-wrap .course-block .course-progress {
  background: #fff;
  border-radius: 50%;
  position: absolute;
  height: 62px;
  width: 62px;
  top: -14px;
  right: -14px;
}

.courses-wrap .course-block .course-progress canvas {
  margin-left: -1px;
  margin-top: -1px;
}

.courses-wrap .course-block .course-pic {
  background: #FCFCFC;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  display: block;
  text-align: center;
  margin-bottom: 20px;
  min-height: 110px;
}

.courses-wrap .course-block .course-pic img {
  margin-top: 10px;
  border-radius: 10px;
  max-height: 100px;
}

.courses-wrap .course-block .course-title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  display: block;
  padding: 0px 10px;
  text-align: center;
}

.courses-wrap .course-block:hover {
  box-shadow: 0 6px 20px 0 rgba(59, 63, 98, 0.12);
}

.courses-wrap.my-courses {
  border-bottom: 1px solid #EFEFEF;
  padding-bottom: 30px;
  margin-bottom: 50px;
}

.courses-wrap.other-courses .course-block .course-title {
  color: #BEBEBE;
}

.courses-wrap.other-courses .course-block .course-pic img {
  filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
  /* Firefox 10+, Firefox on Android */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+, Safari 6+, Safari 6+ iOS */
  opacity: 0.25;
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}

.step-content-wrap {
  margin-bottom: 60px;
  flex: 1 0 auto;
}

.step-content-wrap .step-content {
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(83, 83, 83, 0.08);
  background: #fff;
  padding: 30px;
  margin-top: 50px;
  overflow: hidden;
}

.step-content-wrap .step-content .step-content-head {
  color: #404040;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 18px;
}

.step-content-wrap .step-content .step-content-text {
  color: #404040;
  font-size: 16px;
}

.step-content-wrap .step-content .with-video-wrap-text {
  display: block;
}

.step-content-wrap .step-content .with-video-wrap-video {
  float: right;
  width: 40%;
}

.step-content-wrap .step-content .with-video-wrap-video .video-block {
  height: auto;
  margin-bottom: 30px;
  margin-top: 54px;
  position: relative;
}

.step-content-wrap .step-content .with-video-wrap-video .video-block .video-overlay {
  background: rgba(24, 42, 75, 0.55);
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.step-content-wrap .step-content .with-video-wrap-video .video-block .video-overlay .play-icn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 172px;
  width: 150px;
  margin: auto;
}

.step-content-wrap .step-content .with-video-wrap-video .video-block .video-overlay .play-icn i {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  display: inline-block;
  font-size: 112px;
  color: #fff;
  line-height: 130px;
  margin-bottom: 10px;
  height: 132px;
  width: 132px;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.step-content-wrap .step-content .with-video-wrap-video .video-block .video-overlay .play-icn .play-title {
  color: #fff;
  display: block;
  font-size: 18px;
  font-weight: 600;
}

.step-content-wrap .step-content .with-video-wrap-video .video-block .video-overlay:hover {
  background: rgba(24, 42, 75, 0.6);
}

.step-content-wrap .step-content .with-video-wrap-video .video-block .video-overlay:hover .play-icn i {
  background: rgba(0, 0, 0, 0.25);
}

.step-content-wrap .step-content .with-video-wrap-video .video-block .video-thumb {
  width: 100%;
}

.step-content-wrap .step-content.lesson .step-content-head {
  color: #404040;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 18px;
  overflow: hidden;
  position: relative;
}

.step-content-wrap .step-content.lesson .step-content-head:after {
  content: "\F474";
  color: rgba(255, 255, 255, 0.15);
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  font-size: 140px;
  right: 30px;
  top: -60px;
  position: absolute;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.step-content-wrap .step-content.lesson .lesson-question {
  color: #404040;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

.step-content-wrap .step-content.lesson .code-wrap {
  background: #FDFDFD;
  border: 1px solid #DEDEDE;
  border-radius: 4px;
  padding: 20px 25px;
  display: block;
}

.step-content-wrap .step-content.lesson .lesson-input {
  border: 1px solid #E6E4EE;
  border-radius: 6px;
  font-size: 16px;
  margin-top: 20px;
  padding: 16px 20px;
  width: 100%;
}

.step-content-wrap .step-content.lesson .lesson-input.error {
  border: 1px solid #FF3B5E;
}

.step-content-wrap .step-content.lesson .lesson-input.on-check {
  border: 1px solid #ffbc00;
}

.step-content-wrap .step-content.lesson .lesson-input.success {
  border: 1px solid #00C832;
}

.step-content-wrap .step-content.lesson .radio-selects label {
  display: block;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
  position: relative;
  padding-left: 28px;
}

.step-content-wrap .step-content.lesson .radio-selects label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.step-content-wrap .step-content.lesson .radio-selects label .checkmark {
  border: 1px solid #D9D9D9;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  -webkit-transition: all 150ms;
  -moz-transition: all 150ms;
  -o-transition: all 150ms;
  transition: all 150ms;
}

.step-content-wrap .step-content.lesson .radio-selects label .checkmark:after {
  background: #fff;
  border-radius: 50%;
  content: "";
  height: 8px;
  width: 8px;
  position: absolute;
  top: 5px;
  left: 5px;
  display: none;
}

.step-content-wrap .step-content.lesson .radio-selects label .multy {
  border: 1px solid #D9D9D9;
  /*border-radius: 50%;*/
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  -webkit-transition: all 150ms;
  -moz-transition: all 150ms;
  -o-transition: all 150ms;
  transition: all 150ms;
}

.step-content-wrap .step-content.lesson .radio-selects label .multy:after {
  background: #fff;
  /*border-radius: 50%;*/
  content: "";
  height: 8px;
  width: 8px;
  position: absolute;
  top: 5px;
  left: 5px;
  display: none;
}

.step-content-wrap .step-content.lesson .radio-selects label input:checked ~ .checkmark:after {
  display: block;
}

.step-content-wrap .step-content.lesson .radio-selects label input:checked ~ .multy:after {
  display: block;
}

.step-content-wrap .step-content.lesson .radio-selects label:hover {
  cursor: pointer;
}

.step-content-wrap .step-content.lesson .radio-selects label:hover input ~ .checkmark {
  background-color: #e6e6e6;
}

.step-content-wrap .step-content.lesson .radio-selects label:hover input ~ .multy {
  background-color: #e6e6e6;
}

.step-content-wrap .step-content.lesson .radio-selects label input:checked ~ .checkmark {
  background-color: #2196F3;
  border-color: #2196F3;
}

.step-content-wrap .step-content.lesson .radio-selects label input:checked ~ .multy {
  background-color: #2196F3;
  border-color: #2196F3;
}

.step-content-wrap .step-content.lesson .radio-selects label input:checked ~ .checkmark:after {
  display: block;
}

.step-content-wrap .step-content.lesson .radio-selects label input:checked ~ .multy:after {
  display: block;
}


.step-content-wrap .step-content.lesson .textarea-code {
  background: #fff;
  border-radius: 6px;
  border: 1px solid #E6E4EE;
  color: #c7254e;
  font-size: 16px;
  padding: 12px;
  margin: 0px;
  width: 100%;
  min-height: 124px;
  resize: vertical;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.step-content-wrap .step-content.lesson .textarea-code:focus,
.step-content-wrap .step-content.lesson .textarea-code:active {
  border: 1px solid #c9c8ce;
  outline: none;
}

.step-content-wrap .step-content.lesson .code-editor {
  border: 1px solid #f6f6f6;
  border-radius: 4px;
  width: 100%;
  min-height: 320px;
  margin-bottom: 40px;
}

.step-content-wrap .step-content.lesson .code-editor.error {
  border: 1px solid #FF3B5E;
}

.step-content-wrap .step-content.lesson .compile-result {
  background: #F8F8F8;
  margin-top: 20px;
  padding: 30px 30px 40px 30px;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 20px;
}

.step-content-wrap .step-content.lesson .compile-result .compile-title-wrap .compile-title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  display: inline-block;
  margin-right: 32px;
}

.step-content-wrap .step-content.lesson .compile-result .compile-title-wrap .history-link {
  color: #4589FD;
  font-size: 18px;
  display: inline-block;
}

.step-content-wrap .step-content.lesson .compile-result .compile-title-wrap .history-link:hover {
  text-decoration: underline;
}

.step-content-wrap .step-content.lesson .compile-result .terminal-result {
  background: #39393A;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 17px;
  padding: 27px 25px;
  margin-top: 30px;
  max-height: 400px;
}

.terminal-result {
  font-size: 14px !important;
  white-space: pre !important;
}

.step-content-wrap .step-content.lesson .stacked-list {
  list-style: none;
  padding: 0px;
}

.step-content-wrap .step-content.lesson .stacked-list .drag-item {
  background: #FDFDFD;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  color: #404040;
  font-size: 16px;
  margin-bottom: 15px;
  padding: 0px;
  max-width: 1200px;
}

.step-content-wrap .step-content.lesson .stacked-list .drag-item .number {
  border-right: 1px solid #E5E5E5;
  color: #818181;
  font-size: 18px;
  display: table-cell;
  width: 44px;
  text-align: center;
  padding: 0px 14px;
}

.step-content-wrap .step-content.lesson .stacked-list .drag-item .item-text {
  display: table-cell;
  padding: 10px 15px;
  max-width: 100%;
  width: 100%;
}

.step-content-wrap .step-content.lesson .stacked-list .drag-item .icon {
  border-left: 1px solid #E5E5E5;
  font-size: 24px;
  color: #D3D3D3;
  display: table-cell;
  text-align: right;
  padding: 0px 8px;
}

.step-content-wrap .step-content.lesson .stacked-list .drag-item .icon svg {
  vertical-align: middle;
}

.step-content-wrap .step-content.lesson .stacked-list .drag-item .icon svg path {
  fill: #D3D3D3;
}

.step-content-wrap .step-content.lesson .stacked-list .draggable-source--is-dragging {
  background: #fff;
  border: 1px solid #679FFF;
  color: #679FFF;
  outline: none;
}

.step-content-wrap .step-content.lesson .stacked-list .drag-item:focus,
.step-content-wrap .step-content.lesson .stacked-list .drag-item:active {
  outline: none;
}

.step-content-wrap .step-content.lesson .stacked-list:focus,
.step-content-wrap .step-content.lesson .stacked-list:active {
  outline: none;
}

.step-content-wrap .next-step-btn {
  background-image: linear-gradient(-48deg, #4b8aff 0%, #407ef2 100%);
  background-size: 100% 100%, cover;
  border-radius: 10px;
  border: none;
  color: #fff;
  display: block;
  font-size: 18px;
  font-weight: 600;
  float: right;
  line-height: 56px;
  height: 60px;
  width: 360px;
  margin-top: 30px;
  text-align: center;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.step-content-wrap .next-step-btn span {
  display: inline-block;
  line-height: 56px;
  vertical-align: top;
}

.step-content-wrap .next-step-btn i {
  font-size: 28px;
  display: inline-block;
  line-height: 58px;
  vertical-align: top;
}

.step-content-wrap .next-step-btn:hover {
  background-size: 400% 100%, cover;
}

.step-content-wrap .lesson-result-row {
  margin-bottom: 10px;
  overflow: hidden;
}

.step-content-wrap .lesson-result-row .lesson-result {
  float: left;
  margin-top: 21px;
}

.step-content-wrap .lesson-result-row .lesson-result i {
  font-size: 26px;
  position: relative;
  top: 2px;
}

.step-content-wrap .lesson-result-row .lesson-result span {
  color: #404040;
  font-size: 18px;
}

.step-content-wrap .lesson-result-row .scroll-up {
  border-bottom: 1px dashed #BCBCBC;
  color: #BCBCBC;
  font-size: 18px;
  float: right;
  margin-right: 30px;
  margin-top: 29px;
  transition: all 200ms;
  cursor: pointer;
}

.step-content-wrap .lesson-result-row .lesson-result.success i {
  color: #00C832;
}

.step-content-wrap .lesson-result-row .lesson-result.error i {
  color: #FF264D;
  top: 4px;
}

.step-content-wrap .lesson-result-row .lesson-result-right-wrap {
  float: right;
}

.step-content-wrap .lesson-result-row .lesson-result-right-wrap .reset-value-btn {
  background: none;
  border: none;
  border-bottom: 1px dashed #BCBCBC;
  color: #BCBCBC;
  font-size: 18px;
  float: left;
  margin-right: 30px;
  margin-top: 29px;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.step-content-wrap .lesson-result-row .lesson-result-right-wrap .reset-value-btn:hover {
  border-bottom: 1px dashed rgba(188, 188, 188, 0.2);
}

.step-content-wrap .lesson-result-row .lesson-result-right-wrap .send-result-btn {
  background: #59DA7A;
  border: 1px solid #59DA7A;
  border-radius: 4px;
  display: block;
  float: left;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-top: 20px;
  text-align: center;
  line-height: 42px;
  width: 276px;
  height: 46px;
  padding: 0px;
  padding-bottom: 2px;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.step-content-wrap .lesson-result-row .lesson-result-right-wrap .send-result-btn:disabled {
  cursor: not-allowed!important;
  background: rgba(89, 218, 122, 0.3);
  border: rgba(89, 218, 122, 0.3);
}

.step-content-wrap .lesson-result-row .lesson-result-right-wrap .send-result-btn:disabled:hover {
  background: rgba(89, 218, 122, 0.3);
  border: rgba(89, 218, 122, 0.3);
}

.step-content-wrap .lesson-result-row .lesson-result-right-wrap .scroll-up {
  background: none;
  border: none;
  border-bottom: 1px dashed #BCBCBC;
  color: #BCBCBC;
  font-size: 18px;
  float: left;
  margin-right: 30px;
  margin-top: 29px;
  transition: all 200ms;
  cursor: pointer;
}

.step-content-wrap .lesson-result-row .lesson-result-right-wrap .send-result-btn:hover {
  background: #4ac469;
  color: #fff;
}

.step-bottom-wrapper .steps-bottom-tabs {
  border-top: 1px solid #E6E4EE;
  padding-top: 60px;
}

.step-bottom-wrapper .steps-bottom-tabs .tabs-wrap {
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
}

.step-bottom-wrapper .steps-bottom-tabs .tabs-wrap .course-tab {
  background: #E5ECF7;
  border-radius: 10px 10px 0px 0px;
  color: #9AB0D5;
  float: left;
  font-weight: 600;
  font-size: 20px;
  padding: 18px 84px;
  text-align: center;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.step-bottom-wrapper .steps-bottom-tabs .tabs-wrap .course-tab:hover {
  color: #86a4d6;
  cursor: pointer;
}

.step-bottom-wrapper .steps-bottom-tabs .tabs-wrap .course-tab.active {
  background: #fff;
  color: #404040;
}

.step-bottom-wrapper .steps-bottom-tabs .tabs-wrap .course-tab.active {
  cursor: auto;
}

.step-bottom-wrapper .steps-bottom-tabs .tab-content {
  background: #fff;
  color: #404040;
  display: none;
  padding: 30px;
}

.step-bottom-wrapper .steps-bottom-tabs .tab-content.active {
  display: block;
}

.step-bottom-wrapper .add-comment-wrap {
  border-bottom: 1px solid #E8E8E8;
  padding-bottom: 40px;
}

.step-bottom-wrapper .add-comment-wrap .add-comment-title {
  color: #404040;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
}

.step-bottom-wrapper .add-comment-wrap form {
  overflow: hidden;
}

.step-bottom-wrapper .add-comment-wrap form textarea {
  background: #fff;
  border-radius: 6px;
  border: 1px solid #E6E4EE;
  color: #404040;
  font-size: 16px;
  padding: 12px 28px 12px 12px;
  margin: 0;
  width: 100%;
  min-height: 124px;
  resize: vertical;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.step-bottom-wrapper .add-comment-wrap form textarea:focus,
.step-bottom-wrapper .add-comment-wrap form textarea:active {
  border: 1px solid #c9c8ce;
  outline: none;
}

.place-comment-btn {
  background: #FFFFFF;
  border: 1px solid #4589FD;
  border-radius: 4px;
  display: block;
  float: right;
  color: #4589FD;
  font-size: 18px;
  font-weight: 700;
  width: 276px;
  height: 46px;
  padding: 0px;
  padding-bottom: 2px;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.place-comment-btn:hover {
  background: #4589FD;
  color: #fff;
}

.step-bottom-wrapper .all-comments-wrap {
  color: #404040;
  margin-top: 40px;
}

.step-bottom-wrapper .all-comments-wrap .all-comments-title {
  color: #404040;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}

.step-bottom-wrapper .all-comments-wrap .comment-block {
  border: 1px solid #F1F1F1;
  padding: 20px;
  margin-bottom: 16px;
}

.comment-block .top-row {
  overflow: hidden;
  margin-bottom: 22px;
}

.step-bottom-wrapper .all-comments-wrap .comment-block .top-row .user-wrap {
  overflow: hidden;
}

.step-bottom-wrapper .all-comments-wrap .comment-block .top-row .user-wrap .user-pic {
  border: 1px solid #f1f1f1;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 14px;
  float: left;
  height: 40px;
  width: 40px;
  /* text-align: center; */
}

.step-bottom-wrapper .all-comments-wrap .comment-block .top-row .user-wrap .user-pic img {
  width: 100%;
  margin: auto;
  object-fit: cover;
  object-position: 50% 50%;
}

.step-bottom-wrapper .all-comments-wrap .comment-block .top-row .user-wrap .user-detail {
  float: left;
}

.step-bottom-wrapper .all-comments-wrap .comment-block .top-row .user-wrap .user-name {
  color: #404040;
  font-size: 18px;
  display: flex;
}

.step-bottom-wrapper .all-comments-wrap .comment-block .top-row .user-wrap .review-date {
  color: #A6A6A6;
  font-size: 14px;
}

.step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-title {
  color: #404040;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
}

.step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container {
  border-top: 1px solid #F2F1F6;
  border-bottom: 1px solid #F2F1F6;
  max-height: 460px;
  margin-bottom: 30px;
  overflow-y: auto;
}

.step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container .im-message {
  width: 50%;
  position: relative;
}

.step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container .im-message .message-userpic {
  background: #fff;
  height: 44px;
  width: 44px;
  border: #fff 2px solid;
  border-radius: 50%;
}

.step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container .im-message .message-userpic img {
  max-height: 44px;
  max-width: 44px;
}

.step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container .im-message .message-text {
  border-radius: 10px;
  font-size: 16px;
  color: #404040;
  margin-bottom: 30px;
}

.step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container .im-message:first-child {
  margin-top: 40px;
}

.step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container .im-message.message-in {
  float: left;
  clear: both;
}

.step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container .im-message.message-in .message-userpic {
  position: absolute;
  left: 0px;
  top: 0px;
}

.step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container .im-message.message-in .message-text {
  background: #EBF6FC;
  padding: 15px 20px 20px 30px;
  margin-left: 20px;
  margin-top: 20px;
  white-space: pre;
}

.step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container .im-message.message-out {
  float: right;
  clear: both;
  margin-right: 20px;
}

.step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container .im-message.message-out .message-userpic {
  position: absolute;
  right: 0px;
  top: 0px;
}

.step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container .im-message.message-out .message-text {
  background: #F6F6F6;
  padding: 15px 30px 20px 20px;
  margin-right: 20px;
  margin-top: 20px;
  white-space: pre;
}

.step-bottom-wrapper .add-im-message-wrap form {
  overflow: hidden;
}

.step-bottom-wrapper .add-im-message-wrap form textarea {
  background: #fff;
  border: 1px solid #E6E4EE;
  border-radius: 6px;
  color: #404040;
  font-size: 16px;
  padding: 12px;
  margin: 0px;
  width: 100%;
  min-height: 124px;
  resize: vertical;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.step-bottom-wrapper .add-im-message-wrap form textarea:focus,
.step-bottom-wrapper .add-im-message-wrap form textarea:active {
  border: 1px solid #c9c8ce;
  outline: none;
}

.step-bottom-wrapper .add-im-message-wrap form .send-message-btn {
  background: #FFFFFF;
  border: 1px solid #4589FD;
  border-radius: 4px;
  display: block;
  float: right;
  color: #4589FD;
  font-size: 18px;
  font-weight: 700;
  margin-top: 16px;
  width: 276px;
  height: 46px;
  padding: 0px;
  padding-bottom: 2px;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.step-bottom-wrapper .add-im-message-wrap form .send-message-btn:hover {
  background: #4589FD;
  color: #fff;
}

.simplebar-scrollbar:before {
  background: #d8d8d8 !important;
}

.user-row {
  background-image: -webkit-linear-gradient(323deg, #5846bf 0%, #413590 100%);
  background-image: -o-linear-gradient(323deg, #5846bf 0%, #413590 100%);
  background-image: linear-gradient(53deg, #5846bf 0%, #413590 100%);
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
}

.user-row .left-wrap {
  float: left;
}

.user-row .user-avatar {
  background: #FFFFFF;
  border-radius: 50%;
  float: left;
  height: 90px;
  width: 90px;
  margin-right: 25px;
}

.user-row .user-avatar img {
  width: 100%;
}

.user-row .user-name-wrap {
  float: left;
  margin-top: 6px;
}

.user-row .user-name-wrap .user-name {
  color: #fff;
  display: block;
  font-size: 34px;
  font-weight: 700;
}

.user-row .user-name-wrap .user-edit-link {
  color: #fff;
  font-size: 18px;
  text-decoration: underline;
}

.user-row .user-name-wrap .user-edit-link:hover {
  text-decoration: none;
}

.user-row .user-stat {
  float: right;
  padding-top: 4px;
}

.user-row .user-stat .stat-block {
  float: left;
  margin-left: 50px;
  text-align: center;
}

.user-row .user-stat .stat-block .stat-head {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 2px;
}

.user-row .user-stat .stat-block .stat-subhead {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}

.auth-wrap {
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(228, 228, 228, 0.4);
  padding: 50px 50px 40px 50px;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  margin-top: 138px;
}

.auth-wrap .logo {
  margin-bottom: 32px;
  text-align: center;
}

.auth-wrap .auth-head {
  color: #404040;
  font-weight: 700;
  margin-bottom: 25px;
  text-align: center;
}

.auth-wrap .auth-head .title {
  font-size: 28px;
}

.auth-wrap form input[type="password"], .auth-wrap form input[type="text"] {
  background: none;
  border: 1px solid #D9D9D9;
  color: #404040;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 15px;
  padding-left: 15px;
  height: 46px;
  width: 100%;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.auth-wrap form input[type="text"]::placeholder {
  color: #AAAAAA;
}

.auth-wrap form input[type="text"]:focus,
.auth-wrap form input[type="text"]:active {
  border: 1px solid #c3c3c3;
}

.auth-wrap form .checkbox {
  font-weight: 400;
  padding-left: 30px;
  position: relative;
  line-height: 1;
}

.auth-wrap form .checkbox input {
  display: none;
}

.auth-wrap form .checkbox .checkmark {
  border: 1px solid #D9D9D9;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.auth-wrap form .checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.auth-wrap form .checkbox input:checked ~ .checkmark:after {
  color: #4B8AFF;
  display: block;
  content: "\F12C";
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: 28px;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  position: absolute;
  top: -8px;
  left: -1px;
}

.auth-wrap form .checkbox:hover {
  cursor: pointer;
}

.auth-wrap form .checkbox:hover input ~ .checkmark {
  border: 1px solid #d1d1d1;
}

.auth-wrap form label.checkbox {
  color: #404040;
  min-height: 20px;
  font-size: 18px;
}

.auth-wrap form .login-btn {
  background-image: linear-gradient(-48deg, #4b8aff 0%, #407ef2 100%);
  background-size: 100% 100%, cover;
  border-radius: 10px;
  border: none;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  margin-top: 26px;
  margin-bottom: 15px;
  height: 60px;
  width: 100%;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.auth-wrap form .login-btn:hover {
  background-size: 400% 100%, cover;
}

.auth-wrap form .lost-password-wrap {
  text-align: center;
}

.auth-wrap form .lost-password-wrap .lost-password-link {
  border-bottom: 1px dashed rgba(75, 138, 255, 0.5);
  color: #4B8AFF;
  font-size: 18px;
  font-weight: 600;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.auth-wrap form .lost-password-wrap .lost-password-link:hover {
  border-bottom: 1px dashed #4b8aff;
}

.auth-wrap.login {
  max-width: 460px;
}

.auth-wrap.register {
  margin-top: 34px;
  max-width: 460px;
}

.auth-wrap.register .secret-word-helper {
  color: #6A6A6A;
  font-size: 13px;
  margin-bottom: 16px;
}

.auth-wrap.register label.checkbox {
  color: #404040;
  font-size: 16px;
  padding-top: 2px;
  margin-bottom: 20px;
}

.auth-wrap.register .date-label {
  width: 100%;
  position: relative;
}

.auth-wrap.register .date-label input {
  width: 100%;
}

.auth-wrap.register .date-label:after {
  border: 1px solid #D9D9D9;
  color: #4B8AFF;
  content: "\F678";
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: 26px;
  height: 46px;
  width: 48px;
  text-align: center;
  line-height: 44px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  position: absolute;
  right: 0px;
  top: 0px;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.auth-wrap.register .date-label:hover:after {
  border: 1px solid #4B8AFF;
  background: #4B8AFF;
  color: #fff;
  cursor: pointer;
}

.auth-wrap.register .login-btn {
  margin-top: 16px;
}

.detail-course-info-wrap {
  margin-top: 50px;
  margin-bottom: 40px;
}

.detail-course-info-wrap .detail-course-info-block {
  border-radius: 10px;
  background: linear-gradient(37.19deg, #4b8dff 0%, #4085fc 100%);
  overflow: hidden;
  padding: 40px;
}

.detail-course-info-wrap .detail-course-info-block .detail-course-text {
  color: #FFFFFF;
  float: left;
  font-size: 18px;
  font-weight: 600;
  padding-right: 58px;
  width: 65%;
}

.detail-course-info-wrap .detail-course-info-block .right-wrap {
  float: left;
  padding-left: 22px;
  width: 35%;
}

.detail-course-info-wrap .detail-course-info-block .right-wrap .info-block {
  position: relative;
  padding-left: 52px;
}

.detail-course-info-wrap .detail-course-info-block .right-wrap .info-block i {
  color: #fff;
  font-size: 40px;
  position: absolute;
  top: -4px;
  left: 0px;
}

.detail-course-info-wrap .detail-course-info-block .right-wrap .info-block .block-text {
  color: #fff;
}

.detail-course-info-wrap .detail-course-info-block .right-wrap .info-block .block-text b {
  font-size: 18px;
  display: block;
}

.detail-course-info-wrap .detail-course-info-block .right-wrap .info-block .block-text span {
  font-size: 16px;
}

.detail-course-info-wrap .detail-course-info-block .right-wrap .info-block:first-child .block-text {
  margin-bottom: 16px;
}

.detail-course-wrap .sbox {
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(83, 83, 83, 0.08);
  background: #fff;
  overflow: hidden;
}

.detail-course-wrap .main-course-info {
  margin-bottom: 30px;
}

.detail-course-wrap .main-course-info .tabs-wrap {
  background: #E5ECF7;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  list-style: none;
}

.detail-course-wrap .main-course-info .tabs-wrap .course-tab {
  background: #E5ECF7;
  border-radius: 10px 10px 0px 0px;
  color: #9AB0D5;
  float: left;
  font-weight: 600;
  font-size: 20px;
  padding: 18px 0px;
  width: 50%;
  text-align: center;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.detail-course-wrap .main-course-info .tabs-wrap .course-tab:hover {
  color: #86a4d6;
  cursor: pointer;
}

.detail-course-wrap .main-course-info .tabs-wrap .course-tab.active {
  background: #fff;
  color: #404040;
}

.detail-course-wrap .main-course-info .tabs-wrap .course-tab.active {
  cursor: auto;
}

.detail-course-wrap .main-course-info .tab-content {
  background: #fff;
  color: #404040;
  display: none;
  padding: 30px;
}

.detail-course-wrap .main-course-info .tab-content.active {
  display: block;
}

.detail-course-wrap .video-block {
  height: 400px;
  margin-bottom: 30px;
  position: relative;
}

.detail-course-wrap .video-block .video-overlay {
  background: rgba(24, 42, 75, 0.55);
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.detail-course-wrap .video-block .video-overlay .play-icn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 172px;
  width: 150px;
  margin: auto;
}

.detail-course-wrap .video-block .video-overlay .play-icn i {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  display: inline-block;
  font-size: 112px;
  color: #fff;
  line-height: 130px;
  margin-bottom: 10px;
  height: 132px;
  width: 132px;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.detail-course-wrap .video-block .video-overlay .play-icn .play-title {
  color: #fff;
  display: block;
  font-size: 18px;
  font-weight: 600;
}

.detail-course-wrap .video-block .video-overlay:hover {
  background: rgba(24, 42, 75, 0.6);
}

.detail-course-wrap .video-block .video-overlay:hover .play-icn i {
  background: rgba(0, 0, 0, 0.25);
}

.detail-course-wrap .video-block .video-thumb {
  max-height: 400px;
  max-width: 100%;
}

.detail-course-wrap .course-description .course-description-head {
  color: #404040;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 14px;
}

.detail-course-wrap .course-description .course-description-text {
  font-size: 16px;
}

.detail-course-wrap .course-lessons .course-lessons-head {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 28px;
}

.detail-course-wrap .course-lessons .lesson-table {
  margin-bottom: 30px;
  overflow: hidden;
  width: 100%;
}

.detail-course-wrap .course-lessons .lesson-table thead tr td {
  background: #F3F4F8;
  border: 1px solid #F3F4F8;
  color: #404040;
  font-weight: 600;
  font-size: 20px;
  padding: 11px 20px;
  text-align: left;
}

.detail-course-wrap .course-lessons .lesson-table tbody tr td {
  border-bottom: 1px solid #F3F4F8;
  color: #404040;
  font-size: 16px;
  padding: 14px;
  text-align: left;
}

.detail-course-wrap .course-lessons .lesson-table tbody tr td i.completed {
  color: #00D134;
  font-size: 22px;
}

.detail-course-wrap .course-lessons .lesson-table tbody tr td a {
  color: #404040;
}

.detail-course-wrap .course-lessons .lesson-table tbody tr td a:hover {
  color: #404040 !important;
  text-decoration: underline !important;
}

.detail-course-wrap .course-lessons .lesson-table tbody tr td:nth-child(1) {
  border-left: 14px solid #F3F4F8;
  padding-left: 22px;
  width: 100%;
}

.detail-course-wrap .course-lessons .lesson-table tbody tr td:nth-child(2) {
  width: 60px;
}

.detail-course-wrap .course-lessons .lesson-table tbody tr td:nth-child(3) {
  border-right: 1px solid #F3F4F8;
  width: 25px;
}

.detail-course-wrap .course-lessons .lesson-table tbody tr.current td:nth-child(1) {
  border-left: 14px solid #679FFF;
}

.reviews-wrap {
  background: #fff;
  display: none;
  padding: 30px;
  padding-bottom: 40px;
}

.reviews-wrap .reviews-head {
  color: #404040;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}

.reviews-wrap .review-block {
  border: 1px solid #F1F1F1;
  color: #404040;
  margin-bottom: 16px;
  padding: 20px;
}

.reviews-wrap .review-block .top-row {
  overflow: hidden;
  margin-bottom: 22px;
}

.reviews-wrap .review-block .top-row .user-wrap {
  overflow: hidden;
}

.reviews-wrap .review-block .top-row .user-wrap .user-pic {
  border: 1px solid #f1f1f1;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 14px;
  float: left;
  height: 40px;
  width: 40px;
}

.reviews-wrap .review-block .top-row .user-wrap .user-pic img {
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.reviews-wrap .review-block .top-row .user-wrap .user-detail {
  float: left;
}

.reviews-wrap .review-block .top-row .user-wrap .user-name {
  color: #404040;
  font-size: 18px;
}

.reviews-wrap .review-block .top-row .user-wrap .review-date {
  color: #A6A6A6;
  font-size: 14px;
}

.reviews-wrap .review-block .top-row .rate-wrap {
  font-size: 0px;
  float: right;
}

.reviews-wrap .review-block .top-row .rate-wrap i {
  color: #E7E7E7;
  font-size: 19px;
  margin-left: 3px;
}

.reviews-wrap .review-block .top-row .rate-wrap.star-1 i:nth-child(1) {
  color: #FFD02B;
}

.reviews-wrap .review-block .top-row .rate-wrap.star-2 i:nth-child(1),
.reviews-wrap .review-block .top-row .rate-wrap.star-2 :nth-child(2) {
  color: #FFD02B;
}

.reviews-wrap .review-block .top-row .rate-wrap.star-3 i:nth-child(1),
.reviews-wrap .review-block .top-row .rate-wrap.star-3 i:nth-child(2),
.reviews-wrap .review-block .top-row .rate-wrap.star-3 i:nth-child(3) {
  color: #FFD02B;
}

.reviews-wrap .review-block .top-row .rate-wrap.star-4 i:nth-child(1),
.reviews-wrap .review-block .top-row .rate-wrap.star-4 i:nth-child(2),
.reviews-wrap .review-block .top-row .rate-wrap.star-4 i:nth-child(3),
.reviews-wrap .review-block .top-row .rate-wrap.star-4 i:nth-child(4) {
  color: #FFD02B;
}

.reviews-wrap .review-block .top-row .rate-wrap.star-5 i:nth-child(1),
.reviews-wrap .review-block .top-row .rate-wrap.star-5 i:nth-child(2),
.reviews-wrap .review-block .top-row .rate-wrap.star-5 i:nth-child(3),
.reviews-wrap .review-block .top-row .rate-wrap.star-5 i:nth-child(4),
.reviews-wrap .review-block .top-row .rate-wrap.star-5 i:nth-child(5) {
  color: #FFD02B;
}

.reviews-wrap .review-block .top-row .review-text {
  clear: both;
}

.reviews-wrap .review-block:last-child {
  margin-bottom: 0px;
}

.reviews-wrap.active {
  display: block;
}

.about-author {
  padding: 30px;
  margin-bottom: 30px;
}

.about-author .about-author-title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 18px;
}

.about-author .author-wrap {
  overflow: hidden;
  margin-bottom: 16px;
}

.about-author .author-wrap .author-pic {
  border-radius: 50%;
  overflow: hidden;
  float: left;
  margin-right: 15px;
  height: 52px;
  width: 52px;
}

.about-author .author-wrap .author-detail .author-name {
  color: #404040;
  font-size: 18px;
  font-weight: 600;
}

.about-author .author-wrap .author-detail .author-detail-info {
  color: #959595;
  font-size: 16px;
}

.about-author .author-text {
  color: #404040;
  font-size: 16px;
}

.requirements-wrap {
  margin-bottom: 30px;
  padding: 30px;
  padding-bottom: 40px;
}

.requirements-wrap .req-wrap {
  border-bottom: 1px solid #E8E6EF;
  margin-bottom: 30px;
}

.requirements-wrap .req-wrap .req-title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 14px;
}

.requirements-wrap .req-wrap .req-text {
  color: #404040;
  font-size: 16px;
  margin-bottom: 30px;
}

.requirements-wrap .target-wrap .target-title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 14px;
}

.requirements-wrap .target-wrap .target-text {
  color: #404040;
  font-size: 16px;
}

.select-course-btn {
  background: #FC5F1F;
  box-shadow: 0 0 10px 0 rgba(83, 83, 83, 0.08);
  border-radius: 10px;
  color: #fff;
  display: block;
  font-size: 18px;
  font-weight: 700;
  height: 60px;
  line-height: 56px;
  text-align: center;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  border: 0px;
  width: 100%;
}

.select-course-btn i {
  font-size: 28px;
  line-height: 1;
  position: relative;
  top: 3px;
}

.select-course-btn:hover {
  background: #f05313;
  color: #fff;
}

.solutions-wrapper .solution-content {
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(83, 83, 83, 0.08);
  background: #fff;
  padding: 30px;
  margin-top: 50px;
  overflow: hidden;
}

.solutions-wrapper .solution-content .solution-content-head {
  margin-bottom: 26px;
}

.solutions-wrapper .solution-content .solution-content-head span {
  color: #404040;
  font-size: 34px;
  font-weight: 700;
}

.solutions-wrapper .solution-content .solution-content-head .return-to-lesson-link {
  color: #4589FD;
  font-size: 18px;
  margin-left: 24px;
  position: relative;
  bottom: 2px;
  cursor: pointer;
  font-weight: normal;
}

.solutions-wrapper .solution-content .solution-content-head .return-to-lesson-link:hover {
  text-decoration: none;
}

.solutions-wrapper .solution-content .solution-sort-wrap select {
  background: #fff;
  border: 1px solid #D9DBE4;
  color: #404040;
  font-size: 18px;
  font-weight: 600;
  padding-left: 20px;
  height: 50px;
  width: 100%;
  transition: 200ms;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.solutions-wrapper .solution-content .solution-sort-wrap select::-ms-expand {
  display: none;
}

.solutions-wrapper .solution-content .solution-sort-wrap select:hover {
  border: 1px solid #c8c9d0;
}

.solutions-wrapper .solution-content .solution-sort-wrap select:active,
.solutions-wrapper .solution-content .solution-sort-wrap select:focus {
  border: 1px solid #c8c9d0;
  outline: none;
}

.solutions-wrapper .solution-content .solution-sort-wrap .select-wrap {
  position: relative;
}

.solutions-wrapper .solution-content .solution-sort-wrap .select-wrap:after {
  content: "\F140";
  color: rgba(64, 64, 64, 0.3);
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: 30px;
  pointer-events: none;
  height: 50px;
  position: absolute;
  top: 0px;
  right: 12px;
  text-rendering: auto;
  line-height: 50px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.solutions-wrapper .solution-content .solutions-list {
  margin-top: 30px;
}

.solutions-wrapper .solution-content .solutions-list table {
  border: 1px solid #EAEBF4;
  width: 100%;
}

.solutions-wrapper .solution-content .solutions-list table tr {
  border-bottom: 1px solid #EAEBF4;
}

.solutions-wrapper .solution-content .solutions-list table tr td {
  padding: 9px 15px;
  width: 50%;
  text-align: left;
}

.solutions-wrapper .solution-content .solutions-list table tr td a {
  color: #679FFF;
  font-size: 16px;
}

.solutions-wrapper .solution-content .solutions-list table tr td a i {
  font-size: 22px;
  vertical-align: middle;
  margin-right: 4px;
}

.solutions-wrapper .solution-content .solutions-list table tr td a i.mdi-check {
  color: #00D134;
}

.solutions-wrapper .solution-content .solutions-list table tr td a i.mdi-close {
  color: #FF264D;
}

.solutions-wrapper .solution-content .solutions-list table tr td a span {
  border-bottom: 1px dashed rgba(103, 159, 255, 0.5);
  transition: all 200ms;
}

.solutions-wrapper .solution-content .solutions-list table tr td a:hover span {
  border-bottom: 1px dashed #679fff;
}

.solutions-wrapper .solution-content .solutions-list table tr td:nth-child(2) {
  color: rgba(64, 64, 64, 0.6);
  font-size: 16px;
  padding-left: 18px;
  text-align: left;
}

.edit-profile-wrapper .edit-profile-content {
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(83, 83, 83, 0.08);
  background: #fff;
  padding: 30px;
  margin-top: 50px;
  overflow: hidden;
}

.edit-profile-wrapper .profile-left-wrap {
  /*border-right: 1px solid #EBE9F1;*/
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 0px;
}

.edit-profile-wrapper .profile-left-wrap .form-field {
  margin-bottom: 30px;
}

.edit-profile-wrapper .profile-left-wrap .form-field .field-label {
  color: #404040;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.edit-profile-wrapper .profile-left-wrap .form-field input {
  border: 1px solid #E6E4EE;
  color: #404040;
  border-radius: 6px;
  font-size: 16px;
  padding-left: 15px;
  padding-right: 15px;
  height: 52px;
  width: 100%;
  transition: all 200ms;
}

.edit-profile-wrapper .profile-left-wrap .form-field input:focus,
.edit-profile-wrapper .profile-left-wrap .form-field input:active {
  border: 1px solid #c3c3c3;
  outline: none;
}

.edit-profile-wrapper .profile-left-wrap .form-field textarea {
  border: 1px solid #E6E4EE;
  border-radius: 6px;
  color: #404040;
  font-size: 16px;
  width: 100%;
  max-width: 100%;
  min-height: 98px;
  padding: 15px 15px;
  transition: all 200ms;
}

.edit-profile-wrapper .profile-left-wrap .form-field textarea:focus,
.edit-profile-wrapper .profile-left-wrap .form-field textarea:active {
  border: 1px solid #c3c3c3;
  outline: none;
}

.edit-profile-wrapper .profile-left-wrap .form-field .change-email-btn {
  border-bottom: 1px dashed rgba(75, 138, 255, 0.5);
  color: #679FFF;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  margin-top: 8px;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.edit-profile-wrapper .profile-left-wrap .form-field .change-email-btn:hover {
  border-bottom: 1px dashed #4b8aff;
}

.edit-profile-wrapper .radio-selects label.radio-btn {
  display: block;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 12px;
  position: relative;
  padding-left: 28px;
}

.edit-profile-wrapper .radio-selects label.radio-btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.edit-profile-wrapper .radio-selects label.radio-btn .checkmark {
  background: #fff;
  border: 1px solid #D9D9D9;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  -webkit-transition: all 150ms;
  -moz-transition: all 150ms;
  -o-transition: all 150ms;
  transition: all 150ms;
}

.edit-profile-wrapper .radio-selects label.radio-btn .checkmark:after {
  background: #fff;
  border-radius: 50%;
  content: "";
  height: 8px;
  width: 8px;
  position: absolute;
  top: 5px;
  left: 5px;
  display: none;
}

.edit-profile-wrapper .radio-selects label.radio-btn input:checked ~ .checkmark:after {
  display: block;
}

.edit-profile-wrapper .radio-selects label.radio-btn:hover {
  cursor: pointer;
}

.edit-profile-wrapper .radio-selects label.radio-btn:hover input ~ .checkmark {
  background-color: #e6e6e6;
}

.edit-profile-wrapper .radio-selects label.radio-btn input:checked ~ .checkmark {
  background-color: #2196F3;
  border-color: #2196F3;
}

.edit-profile-wrapper .radio-selects label.radio-btn input:checked ~ .checkmark:after {
  display: block;
}

.edit-profile-wrapper .profile-avatar-wrap {
  margin-top: 30px;
  text-align: center;
}

.edit-profile-wrapper .profile-avatar-wrap .profile-avatar-pic {
  border-radius: 50%;
  display: block;
  max-width: 200px;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 10px;
  height: 200px;
  width: 200px;
}

.edit-profile-wrapper .profile-avatar-wrap .profile-avatar-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.edit-profile-wrapper .profile-avatar-wrap .change-profile-pic {
  border-bottom: 1px dashed rgba(75, 138, 255, 0.5);
  color: #679FFF;
  font-size: 16px;
  font-weight: 600;
  margin-top: 8px;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
  cursor: pointer;
}

.edit-profile-wrapper .profile-avatar-wrap .change-profile-pic:hover {
  border-bottom: 1px dashed #4b8aff;
}

.edit-profile-wrapper .profile-top {
  /*border-bottom: 1px solid #EBE9F1;*/
  overflow: hidden;
  padding-bottom: 30px;
}

.edit-profile-wrapper .profile-bottom .save-changes-btn {
  background: #59DA7A;
  border-radius: 4px;
  color: #fff;
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  height: 46px;
  margin-top: 35px;
  margin-bottom: 10px;
  line-height: 44px;
  width: 252px;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
  border: blue;
}

.edit-profile-wrapper .profile-bottom .save-changes-btn:hover {
  background: #4ac469;
  color: #fff;
}

.stat-courses-popup {
  background: #F6F6F6;
  width: 560px;
}

.stat-courses-popup .popup-head {
  background: #fff;
  margin-top: -30px;
  margin-left: -30px;
  margin-right: -30px;
  padding: 25px 30px;
  text-align: center;
  color: #404040;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 40px;
}

.stat-courses-popup .courses-popup-wrap .course-block {
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(59, 63, 98, 0.08);
  border-radius: 10px;
  padding-bottom: 30px;
  display: block;
  position: relative;
  margin-bottom: 30px;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.stat-courses-popup .courses-popup-wrap .course-block .course-progress {
  background: #fff;
  border-radius: 50%;
  position: absolute;
  height: 62px;
  width: 62px;
  top: -14px;
  right: -14px;
}

.stat-courses-popup .courses-popup-wrap .course-block .course-progress canvas {
  margin-left: -1px;
  margin-top: -1px;
}

.stat-courses-popup .courses-popup-wrap .course-block .course-pic {
  background: #FCFCFC;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

.stat-courses-popup .courses-popup-wrap .course-block .course-pic img {
  width: 100%;
  max-width: 100%;
}

.stat-courses-popup .courses-popup-wrap .course-block .course-title {
  color: #404040;
  font-size: 24px;
  font-weight: 700;
  display: block;
  padding: 0px 10px;
  text-align: center;
}

.stat-courses-popup .courses-popup-wrap .course-block:hover {
  box-shadow: 0 6px 20px 0 rgba(59, 63, 98, 0.12);
}

.solution-popup {
  margin: auto;
  max-width: 1060px;
  width: 100%;
}

.solution-popup .solution-popup-head-wrap {
  margin-bottom: 6px;
}

.solution-popup .solution-popup-head {
  color: #404040;
  font-size: 28px;
  font-weight: 700;
  display: inline-block;
}

.solution-popup .solution-date {
  color: #929292;
  font-size: 16px;
  display: inline-block;
  margin-left: 25px;
  position: relative;
  bottom: 1px;
}

.solution-popup .solution-question {
  color: #404040;
  font-size: 16px;
  margin-bottom: 25px;
}

.solution-popup .code-editor {
  border: 1px solid #f6f6f6;
  border-radius: 4px;
  width: 100%;
  min-height: 320px;
  margin-bottom: 40px;
}

.solution-popup .code-editor.error {
  border: 1px solid #FF3B5E;
  color: black;
}

.solution-popup .code-editor.success {
  border: 1px solid #59DA7A;
  color: black;
}

.solution-popup .compile-result {
  background: #F8F8F8;
  margin-top: 20px;
  padding: 30px 30px 40px 30px;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 20px;
}

.solution-popup .compile-result .compile-title-wrap .compile-title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  display: inline-block;
  margin-right: 32px;
}

.solution-popup .compile-result .compile-title-wrap .history-link {
  color: #4589FD;
  font-size: 18px;
  display: inline-block;
}

.solution-popup .compile-result .compile-title-wrap .history-link:hover {
  text-decoration: underline;
}

.solution-popup .compile-result .terminal-result {
  background: #39393A;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 17px;
  padding: 27px 25px;
  margin-top: 30px;
  white-space: pre;
}

.solution-popup .lesson-result-row {
  margin-bottom: 10px;
  overflow: hidden;
  font-size: 20px;
}

.solution-popup .lesson-result-row .lesson-result {
  float: left;
  margin-top: 17px;
}

.solution-popup .lesson-result-row .lesson-result i {
  font-size: 26px;
  position: relative;
  top: 2px;
}

.solution-popup .lesson-result-row .lesson-result span {
  color: #404040;
  font-size: 18px;
}

.solution-popup .lesson-result-row .lesson-result.success i {
  color: #00C832;
}

.solution-popup .lesson-result-row .lesson-result.error i {
  color: #FF264D;
  top: 4px;
}

.solution-popup .lesson-result-row .lesson-result-right-wrap {
  float: right;
  margin-top: 15px;
}

.solution-popup .lesson-result-row .lesson-result-right-wrap .solution-btn {
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  color: #8E8E8E;
  font-size: 16px;
  font-weight: 700;
  float: left;
  height: 48px;
  line-height: 42px;
  display: block;
  width: 250px;
  text-align: center;
  transition: all 200ms;
}

.solution-popup .lesson-result-row .lesson-result-right-wrap .solution-btn i {
  color: #D1D1D1;
  font-size: 22px;
  position: relative;
  vertical-align: top;
  top: 4px;
}

.solution-popup .lesson-result-row .lesson-result-right-wrap .solution-btn:hover {
  border: 1px solid #b2b2b2;
  color: #7e7e7e;
}

.solution-popup .lesson-result-row .lesson-result-right-wrap .solution-btn.prev {
  margin-right: 15px;
}

.simple-page .content {
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(83, 83, 83, 0.08);
  background: #fff;
  padding: 30px;
  margin-top: 50px;
  overflow: hidden;
}

.simple-page .page-not-found {
  padding-top: 10px;
  text-align: center;
}

.simple-page .page-not-found .big-text {
  color: #4B8DFF;
  font-size: 190px;
  font-weight: 700;
  margin-bottom: -30px;
  opacity: 0.15;
}

.simple-page .page-not-found .not-found-head {
  color: #404040;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 4px;
}

.simple-page .page-not-found .not-found-text {
  font-size: 22px;
  margin-bottom: 40px;
}

.simple-page .page-not-found .not-found-text a {
  color: #679FFF;
}

.simple-page .page-not-found .not-found-text a:hover {
  text-decoration: underline;
}

.footer {
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(228, 228, 228, 0.4);
  margin-top: 120px;
  padding-top: 54px;
  padding-bottom: 48px;
  overflow: hidden;
}

.footer .left-wrap {
  float: left;
}

.footer .left-wrap .logo {
  display: inline-block;
  margin-bottom: 18px;
}

.footer .left-wrap .footer-links ul {
  float: left;
  list-style: none;
  padding: 0px;
  margin-right: 40px;
}

.footer .left-wrap .footer-links ul li {
  margin-bottom: 15px;
}

.footer .left-wrap .footer-links ul li a {
  color: #9E9E9E;
  font-size: 16px;
  text-decoration: underline;
}

.footer .left-wrap .footer-links ul li a:hover {
  text-decoration: none;
}

.footer .right-wrap {
  float: right;
}

.footer .right-wrap .soc-links {
  padding: 0px;
  list-style: none;
  font-size: 0px;
  margin-bottom: 16px;
}

.footer .right-wrap .soc-links li {
  display: inline-block;
  margin-left: 20px;
}

.footer .right-wrap .soc-links li a {
  border: 2px solid #4B8AFF;
  border-radius: 50%;
  color: #4B8AFF;
  display: inline-block;
  font-size: 25px;
  text-align: center;
  line-height: 42px;
  height: 48px;
  width: 48px;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.footer .right-wrap .soc-links li a:hover {
  background: #4B8AFF;
  color: #fff;
}

.footer .right-wrap .copyright {
  color: #D2D2D2;
  font-size: 16px;
  text-align: right;
}

.mobile-menu {
  display: none;
}

.mobile-menu-btn {
  width: 30px;
  height: 45px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.mobile-menu-btn span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #4B8AFF;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.mobile-menu-btn span:nth-child(1) {
  top: 0px;
}

.mobile-menu-btn span:nth-child(2),
.mobile-menu-btn span:nth-child(3) {
  top: 10px;
}

.mobile-menu-btn span:nth-child(4) {
  top: 20px;
}

.mobile-menu-btn.open span:nth-child(1) {
  top: 10px;
  width: 0%;
  left: 50%;
}

.mobile-menu-btn.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mobile-menu-btn.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mobile-menu-btn.open span:nth-child(4) {
  top: 10px;
  width: 0%;
  left: 50%;
}

.mobile-menu {
  height: 100vh;
  position: fixed;
  background: #fff;
  color: #333;
  width: 100%;
  z-index: 9;
}

.mobile-menu ul {
  list-style: none;
  padding: 0px;
}

.mobile-menu ul li {
  border-bottom: 1px solid #eaeaea;
}

.mobile-menu ul li a {
  color: #404040;
  font-size: 18px;
  font-weight: 700;
  display: block;
  padding: 20px 10px;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.mobile-menu ul li a:hover,
.mobile-menu ul li a:active,
.mobile-menu ul li a:focus {
  color: #4B8AFF;
}

@media (min-width: 1300px) {
  .container {
    width: 1290px !important;
    max-width: 1290px !important;
  }
}

@media (max-width: 1300px) {
  .detail-course-wrap .video-block {
    height: auto !important;
  }

  .header .top-header .pageHostLabel {
    display: none;
  }

  .courses-wrap .course-block .course-title {
    font-size: 18px !important;
  }

  .step-content-wrap .step-content.lesson .stacked-list .drag-item {
    max-width: 1080px;
  }
}

@media (max-width: 1200px) {
  .step-content-wrap .step-content .with-video-wrap-video .video-block .video-overlay .play-icn {
    height: 130px !important;
  }

  .step-content-wrap .step-content .with-video-wrap-video .video-block .video-overlay .play-icn i {
    height: 96px !important;
    width: 96px !important;
    font-size: 74px !important;
    line-height: 96px !important;
  }

  .courses-wrap .course-block {
    padding-bottom: 20px !important;
  }

  .courses-wrap .course-block .course-title {
    font-size: 16px !important;
  }

  .courses-wrap.my-courses {
    padding-bottom: 30px !important;
    margin-bottom: 30px !important;
  }

  .step-content-wrap .step-content.lesson .stacked-list .drag-item {
    max-width: 880px;
  }

  .solution-popup .compile-result .terminal-result {
    margin-top: 20px;
  }

  .solution-popup {
    max-width: 920px;
  }

  .solution-popup .lesson-result-row .lesson-result {
    margin-top: 0px;
  }

  .solution-popup .lesson-result-row .lesson-result-right-wrap {
    float: left;
    margin-top: 25px;
  }
}

@media (max-width: 992px) {
  .detail-course-info-wrap .detail-course-info-block {
    padding: 40px 30px !important;
  }

  .detail-course-info-wrap .detail-course-info-block .detail-course-text {
    float: none !important;
    padding-right: 0px !important;
    margin-bottom: 20px !important;
    width: 100% !important;
  }

  .middle-header .page-title-wrap .page-title {
    font-size: 36px !important;
  }

  .middle-header.all-courses .page-title-wrap .page-title {
    font-size: 36px !important;
  }

  .detail-course-info-wrap .detail-course-info-block .right-wrap {
    float: none !important;
    width: 100% !important;
  }

  .detail-course-info-wrap .detail-course-info-block .right-wrap .info-block {
    float: left !important;
    width: 50% !important;
  }

  .detail-course-wrap .course-left-wrap {
    margin-bottom: 30px !important;
  }

  .header .top-header .header-right-wrap .profile-menu-wrap {
    right: 14px !important;
  }

  .header .top-header .header-right-wrap .profile-menu-wrap:before {
    right: 54px !important;
  }

  .header .top-header .header-right-wrap .profile-menu-wrap:after {
    right: 44px !important;
  }

  .header .top-header .header-right-wrap {
    padding-right: 60px !important;
  }

  .header .top-header .header-right-wrap .mobile-menu-btn {
    display: block !important;
    position: absolute !important;
    right: 0px !important;
    top: 20px !important;
  }

  .header .top-header .top-nav {
    display: none !important;
  }

  .middle-header.lesson-step .page-title-course-pic {
    margin-top: 6px !important;
  }

  .middle-header.lesson-step .page-title-wrap .page-title-progress {
    display: block;
    font-size: 16px;
    margin-top: 4px;
  }

  .middle-header.lesson-step .lesson-navigation {
    margin-top: 8px;
  }

  .middle-header .page-title-wrap .breadcrumbs .link a {
    font-size: 18px;
  }

  .detail-course-wrap .main-course-info .tabs-wrap .course-tab {
    font-size: 18px;
    list-style: none;
  }

  .step-bottom-wrapper .steps-bottom-tabs .tabs-wrap .course-tab {
    font-size: 18px;
  }

  .step-bottom-wrapper .add-comment-wrap .add-comment-title {
    font-size: 22px;
    margin-bottom: 18px;
  }

  .place-comment-btn {
    font-size: 16px;
    min-width: auto;
    max-width: 248px;
  }

  .step-bottom-wrapper .add-im-message-wrap form .send-message-btn {
    font-size: 16px;
    min-width: auto;
    max-width: 248px;
  }

  .step-content-wrap .next-step-btn {
    height: 54px;
    line-height: 52px;
    width: 280px;
  }

  .step-content-wrap .next-step-btn span {
    line-height: 52px;
  }

  .step-content-wrap .next-step-btn i {
    line-height: 52px;
  }

  .step-content-wrap .step-content.lesson .step-content-head {
    font-size: 20px;
  }

  .step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container .im-message {
    width: 80%;
  }

  .step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container .im-message .message-text {
    font-size: 15px;
  }

  .step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container {
    max-height: 340px;
  }

  .step-content-wrap .step-content .with-video-wrap-text {
    float: none;
    width: 100%;
    padding-right: 0px;
  }

  .step-content-wrap .step-content .with-video-wrap-video {
    float: none;
    width: 100%;
  }

  .step-content-wrap .step-content .with-video-wrap-video .video-block {
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .quote-wrap {
    padding: 24px 30px;
  }

  .quote-wrap .quote-text {
    font-size: 18px;
  }

  .quote-wrap .quote-author {
    font-size: 16px;
  }

  .step-content-wrap .lesson-result-row .lesson-result {
    float: none;
  }

  .step-content-wrap .lesson-result-row .lesson-result-right-wrap {
    float: none;
    overflow: hidden;
  }

  .step-content-wrap .lesson-result-row .lesson-result-right-wrap .send-result-btn {
    cursor: pointer;
    float: right;
    font-size: 16px;
    max-width: 248px;
  }

  .step-content-wrap .lesson-result-row .lesson-result-right-wrap .reset-value-btn {
    font-size: 16px;
    margin-top: 30px;
  }

  .step-content-wrap .step-content.lesson .compile-result {
    margin-bottom: 0px;
    padding: 20px 30px 25px 30px;
  }

  .step-content-wrap .step-content.lesson .compile-result .terminal-result {
    padding: 18px 15px;
    font-size: 15px;
    margin-top: 20px;
  }

  .solution-popup {
    max-width: 750px;
  }

  .solution-popup .solution-popup-head {
    font-size: 24px;
  }

  .solution-popup .solution-date {
    font-size: 15px;
    bottom: 0px;
    margin-left: 20px;
  }

  .solution-popup .compile-result .terminal-result {
    padding: 18px 20px;
  }

  .footer {
    margin-top: 80px;
  }

  .footer .left-wrap .logo {
    max-width: 220px;
  }

  .footer .left-wrap .logo img {
    width: 100%;
  }

  .footer .left-wrap .footer-links ul {
    float: none;
  }

  .footer .left-wrap .footer-links ul li {
    margin-bottom: 10px;
  }

  .step-content-wrap .step-content.lesson .stacked-list .drag-item {
    max-width: 660px;
  }

  .solutions-wrapper .solution-content .solution-sort-wrap select {
    font-size: 15px;
    height: 44px;
  }

  .solutions-wrapper .solution-content .solution-sort-wrap .select-wrap:after {
    height: 44px;
    line-height: 44px;
    right: 5px;
    font-size: 26px;
  }

  .solutions-wrapper .solution-content .solution-content-head span {
    font-size: 28px;
  }

  .solutions-wrapper .solution-content .solution-content-head .return-to-lesson-link {
    font-size: 16px;
  }

  .solution-popup .compile-result {
    padding: 20px 20px 20px 20px;
    margin-left: -20px;
    margin-right: -20px;
  }

  .edit-profile-wrapper .profile-left-wrap {
    padding: 0px;
    border-right: none;
  }

  .edit-profile-content .profile-top {
    border-bottom: none;
  }

  .edit-profile-content .profile-top .col-sm-4 {
    border-bottom: 1px solid #EBE9F1;
    border-top: 1px solid #EBE9F1;
    padding-top: 5px;
    padding-bottom: 30px;
    margin-top: 15px;
  }

  .edit-profile-wrapper .profile-bottom .save-changes-btn {
    margin: 0 auto;
    margin-top: 15px;
  }

  .edit-profile-wrapper .radio-selects label.radio-btn {
    font-size: 16px;
  }

  .simple-page .page-not-found .big-text {
    font-size: 120px;
    margin-bottom: -20px;
  }

  .simple-page .page-not-found .not-found-head {
    font-size: 24px;
  }

  .simple-page .page-not-found .not-found-text {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

@media (max-width: 700px) {
  .header .top-header .logo {
    max-width: 200px !important;
    margin-top: 20px !important;
  }

  .header .top-header .logo img {
    width: 100%;
  }

  .middle-header .page-title-wrap .page-title {
    font-size: 30px;
  }

  .middle-header.all-courses .page-title-wrap .page-title {
    font-size: 30px;
  }

  .middle-header .page-title-course-pic {
    height: 68px;
    width: 102px;
    margin-right: 15px;
  }

  .middle-header .page-title-course-pic img {
    height: auto;
  }

  .detail-course-info-wrap .detail-course-info-block {
    padding: 30px 30px;
  }

  .detail-course-info-wrap .detail-course-info-block .right-wrap {
    padding-left: 0px;
  }

  .detail-course-info-wrap .detail-course-info-block .right-wrap .info-block {
    float: none;
    width: 100%;
  }

  .detail-course-wrap .main-course-info .tabs-wrap .course-tab {
    font-size: 18px;
  }

  .middle-header.lesson-step .page-title-course-pic {
    width: 120px;
    height: 80px;
  }

  .middle-header.lesson-step .lesson-navigation {
    margin-top: 16px;
  }

  .step-bottom-wrapper .steps-bottom-tabs .tabs-wrap {
    background: #E5ECF7;
  }

  .step-bottom-wrapper .steps-bottom-tabs .tabs-wrap .course-tab {
    padding: 18px 0px;
    width: 50%;
  }

  .solution-popup .compile-result .compile-title-wrap .compile-title {
    font-size: 16px;
  }

  .solution-popup .compile-result .terminal-result {
    font-size: 16px;
  }

  .solution-popup .lesson-result-row .lesson-result span {
    font-size: 16px;
  }

  .solution-popup .lesson-result-row .lesson-result-right-wrap {
    float: none;
    overflow: hidden;
    clear: both;
  }

  .solution-popup .lesson-result-row .lesson-result-right-wrap .solution-btn {
    font-size: 15px;
    height: 40px;
    line-height: 30px;
    float: none;
  }

  .solution-popup .lesson-result-row .lesson-result-right-wrap .solution-btn.prev {
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .solution-popup .lesson-result-row .lesson-result-right-wrap .solution-btn.next {
    margin: 0 auto;
  }

  .solution-popup .lesson-result-row .lesson-result {
    margin-bottom: 24px;
  }

  .footer {
    text-align: center;
  }

  .footer .left-wrap {
    text-align: center;
    float: none;
  }

  .footer .left-wrap .footer-links ul {
    margin-right: 0px;
  }

  .footer .right-wrap {
    float: none;
    margin-top: 34px;
  }

  .footer .right-wrap .soc-links li {
    margin: 0px 10px;
  }

  .footer .right-wrap .copyright {
    text-align: center;
  }
}

@media (max-width: 630px) {
  .header .top-header .header-right-wrap .notify-message-wrap {
    right: -104px;
  }

  .header .top-header .header-right-wrap .notify-message-wrap:before {
    right: 120px;
  }

  .header .top-header .header-right-wrap .notify-message-wrap:after {
    right: 110px;
    height: 34px;
  }

  .solutions-wrapper .solution-content .solutions-list table tr td {
    width: auto;
  }

  .solutions-wrapper .solution-content .solutions-list table tr td:nth-child(1) {
    min-width: 190px;
    width: 194px;
  }

  .solutions-wrapper .solution-content .solutions-list table tr td a span {
    font-size: 14px;
  }

  .solutions-wrapper .solution-content .solutions-list table tr td a i {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 3px;
  }

  .solutions-wrapper .solution-content .solutions-list table tr td:nth-child(2) {
    font-size: 14px;
    text-align: right;
  }

  .solutions-wrapper .solution-content .solution-sort-wrap .col-xs-6 {
    width: 100%;
  }

  .solutions-wrapper .solution-content .solution-sort-wrap .col-xs-6:first-child {
    margin-bottom: 15px;
  }
}

@media (max-width: 560px) {
  .detail-course-wrap .video-block .video-overlay .play-icn {
    height: 114px;
  }

  .detail-course-wrap .video-block .video-overlay .play-icn i {
    font-size: 70px;
    line-height: 80px;
    height: 80px;
    width: 80px;
  }

  .header .top-header .header-right-wrap .notify-message-wrap {
    width: 342px;
  }

  .step-bottom-wrapper .mentor-dialog-wrap .mentor-dialog-container .im-message {
    width: 90%;
  }

  .courses-wrap .col-xs-6 {
    width: 100%;
  }

  .step-content-wrap .lesson-result-row .lesson-result-right-wrap {
    text-align: center;
  }

  .step-content-wrap .lesson-result-row .lesson-result span {
    font-size: 16px;
  }

  .step-content-wrap .lesson-result-row .lesson-result-right-wrap .reset-value-btn {
    float: none;
    display: inline-block;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .step-content-wrap .lesson-result-row .lesson-result-right-wrap .send-result-btn {
    cursor: pointer;
    float: none;
    margin: 0 auto;
  }

  .step-content-wrap .lesson-result-row .lesson-result-right-wrap .send-result-btn:disabled {
    cursor: not-allowed!important;
    background: rgba(89, 218, 122, 0.3);
    border: rgba(89, 218, 122, 0.3);
  }

  .step-content-wrap .lesson-result-row .lesson-result-right-wrap .send-result-btn:disabled:hover {
    background: rgba(89, 218, 122, 0.3);
    border: rgba(89, 218, 122, 0.3);
  }
}

@media (max-width: 470px) {
  .header .top-header .logo {
    display: none;
    margin-right: 0px;
  }

  .middle-header.lesson-step .page-title-course-pic {
    float: none;
    margin-bottom: 15px;
  }
}

@media (max-width: 400px) {
  .header .top-header .header-right-wrap .notify-message-wrap {
    width: 300px;
  }

  .header .top-header .header-right-wrap .notify-message-wrap .notify-message .notify-text-wrap {
    display: block;
    overflow: hidden;
  }
}

.step-content-wrap .step-content .with-video-wrap-video .video-block .video-overlay .play-icn {
  height: 114px;
}

.step-content-wrap .step-content .with-video-wrap-video .video-block .video-overlay .play-icn i {
  height: 80px;
  width: 80px;
  line-height: 80px;
  font-size: 64px;
}

.step-content-wrap .step-content.lesson .stacked-list {
  list-style: none;
  padding: 0px;
}

.step-content-wrap .step-content.lesson .stacked-list .drag-item {
  background: #FDFDFD;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  color: #404040;
  font-size: 16px;
  margin-bottom: 15px;
  padding: 0px;
  max-width: 1200px;
}

.step-content-wrap .step-content.lesson .stacked-list .drag-item .number {
  border-right: 1px solid #E5E5E5;
  color: #818181;
  font-size: 18px;
  display: table-cell;
  width: 44px;
  text-align: center;
  padding: 0px 14px;
}

.step-content-wrap .step-content.lesson .stacked-list .drag-item .item-text {
  display: table-cell;
  padding: 10px 15px;
  max-width: 100%;
  width: 100%;
}

.step-content-wrap .step-content.lesson .stacked-list .drag-item .icon {
  border-left: 1px solid #E5E5E5;
  font-size: 24px;
  color: #D3D3D3;
  display: table-cell;
  text-align: right;
  padding: 0px 8px;
}

.step-content-wrap .step-content.lesson .stacked-list .drag-item .icon svg {
  vertical-align: middle;
}

.step-content-wrap .step-content.lesson .stacked-list .drag-item .icon svg path {
  fill: #D3D3D3;
}

.step-content-wrap .step-content.lesson .stacked-list .draggable-source--is-dragging {
  background: #fff;
  border: 1px solid #679FFF;
  color: #679FFF;
  outline: none;
}

.step-content-wrap .step-content.lesson .stacked-list .drag-item:focus,
.step-content-wrap .step-content.lesson .stacked-list .drag-item:active {
  outline: none;
}

.simple-page .content {
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(83, 83, 83, 0.08);
  background: #fff;
  padding: 30px;
  margin-top: 50px;
  overflow: hidden;
}

.simple-page .page-not-found {
  padding-top: 10px;
  text-align: center;
}

.simple-page .page-not-found .big-text {
  color: #4B8DFF;
  font-size: 190px;
  font-weight: 700;
  margin-bottom: -30px;
  opacity: 0.15;
}

.simple-page .page-not-found .not-found-head {
  color: #404040;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 4px;
}

.simple-page .page-not-found .not-found-text {
  font-size: 22px;
  margin-bottom: 40px;
}

.simple-page .page-not-found .not-found-text a {
  color: #679FFF;
}

.simple-page .page-not-found .not-found-text a:hover {
  text-decoration: underline;
}

.save-changes-btn {
  background: #59DA7A;
  border-radius: 4px;
  color: #fff;
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  height: 46px;
  margin-top: 35px;
  margin-bottom: 10px;
  line-height: 44px;
  width: 252px;
  /*-webkit-transition(all 200 ms);*/
}

.step-content-wrap .step-content.lesson .code-editor {
  border: 1px solid #f6f6f6;
  border-radius: 4px;
  width: 100%;
  min-height: 320px;
  margin-bottom: 40px;
}

.step-content-wrap .step-content.lesson .code-editor.error-code-result {
  border: 1px solid #FF3B5E;
}

.step-content-wrap .step-content.lesson .code-editor.success-code-result {
  border: 1px solid #59DA7A;
}

.step-content-wrap .step-content.lesson .compile-result {
  background: #F8F8F8;
  margin-top: 20px;
  padding: 30px 30px 40px 30px;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 20px;
}

.step-content-wrap .step-content.lesson .compile-result .compile-title-wrap .compile-title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  display: inline-block;
  margin-right: 32px;
}

.step-content-wrap .step-content.lesson .compile-result .compile-title-wrap .history-link {
  color: #4589FD;
  font-size: 18px;
  display: inline-block;
}

.step-content-wrap .step-content.lesson .compile-result .compile-title-wrap .history-link:hover {
  text-decoration: underline;
}

.step-content-wrap .step-content.lesson .compile-result .terminal-result {
  background: #39393A;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 17px;
  padding: 27px 25px;
  margin-top: 30px;
  overflow-y: auto;
}

.profile-quote-wrap {
  background-image: linear-gradient(-38deg, #4b8dff 0%, #4085fc 100%);
  padding: 30px 40px;
  position: relative;
}

.profile-quote-wrap .quote-text {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}

.profile-quote-wrap .quote-author {
  color: #fff;
  font-style: italic;
  font-size: 20px;
  font-weight: 600;
}

.profile-page-wrap .profile-container {
  box-shadow: 0 0 10px 0 rgba(83, 83, 83, 0.08);
  background: #fff;
  padding: 30px;
  padding-top: 40px;
  overflow: hidden;
  margin-bottom: -120px;
}

.profile-page-wrap .profile-container .profile-progress-wrap {
  border-bottom: 1px solid #EBE9F1;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-head {
  color: #404040;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 24px;
  /*display: none;*/
}

.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-bar {
  background: #EBEDF0;
  border: none;
  border-radius: 6px;
  margin-bottom: 20px;
  height: 12px;
  width: 88%;
  margin-left: 6%;
  margin-right: 6%;
  position: relative;
  /*display: none;*/
}

.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-bar .progress-fill {
  border-radius: 6px;
  height: 12px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-bar .progress-fill svg {
  position: absolute;
  right: -5px;
  top: -11px;
  border: none;
}

.progress-title.progress-0 {
  /*width: 6% !important;*/
  margin-right: 0.5%;
}

.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-bar .progress-fill.progress-0 {
  background-color: #BB1614;
  background-image: url('data:image/svg+xml;utf8, <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Fill-2" fill="rgba(255, 255, 255, 0.15)"><polygon points="0 0 6.17663675 5.99981707 0 12 5.82336325 12 12 5.99981707 5.82336325 0"></polygon></g></g></svg>');
  width: 0%;
}

.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-bar .progress-fill.progress-0 #progress-arrow {
  fill: #BB1614;
}

.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-bar .progress-fill.progress-5 {
  background-color: #E25B1E;
  background-image: url('data:image/svg+xml;utf8, <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Fill-2" fill="rgba(255, 255, 255, 0.15)"><polygon points="0 0 6.17663675 5.99981707 0 12 5.82336325 12 12 5.99981707 5.82336325 0"></polygon></g></g></svg>');
  width: 14%;
}

.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-bar .progress-fill.progress-5 #progress-arrow {
  fill: #E25B1E;
}


.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-bar .progress-fill.progress-35 {
  background-color: #008B8C;
  background-image: url('data:image/svg+xml;utf8, <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Fill-2" fill="rgba(255, 255, 255, 0.15)"><polygon points="0 0 6.17663675 5.99981707 0 12 5.82336325 12 12 5.99981707 5.82336325 0"></polygon></g></g></svg>');
  width: 33.3%;
}

.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-bar .progress-fill.progress-35 #progress-arrow {
  fill: #008B8C;
}

.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-bar .progress-fill.progress-57 {
  background: #4077d1;
  background-image: url('data:image/svg+xml;utf8, <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Fill-2" fill="rgba(255, 255, 255, 0.15)"><polygon points="0 0 6.17663675 5.99981707 0 12 5.82336325 12 12 5.99981707 5.82336325 0"></polygon></g></g></svg>');
  width: 52.2%;
}

.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-bar .progress-fill.progress-57 #progress-arrow {
  fill: #4077d1;
}

.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-bar .progress-fill.progress-88 {
  background-color: #30538d;
  background-image: url('data:image/svg+xml;utf8, <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Fill-2" fill="rgba(255, 255, 255, 0.15)"><polygon points="0 0 6.17663675 5.99981707 0 12 5.82336325 12 12 5.99981707 5.82336325 0"></polygon></g></g></svg>');
  width: 71.5%;
}

.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-bar .progress-fill.progress-88 #progress-arrow {
  fill: #30538d;
}

.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-bar .progress-fill.progress-100 {
  background-color: #131a8c;
  background-image: url('data:image/svg+xml;utf8, <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Fill-2" fill="rgba(255, 255, 255, 0.15)"><polygon points="0 0 6.17663675 5.99981707 0 12 5.82336325 12 12 5.99981707 5.82336325 0"></polygon></g></g></svg>');
  width: 100%;
}

.profile-page-wrap .profile-container .profile-progress-wrap .profile-progress-bar .progress-fill.progress-100 #progress-arrow {
  fill: #131a8c;
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap {
  overflow: hidden;
  /*display: none;*/
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title {
  float: left;
  font-weight: 600;
  font-size: 16px;
  width: 14.28571%;
  text-align: center;
  position: relative;
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title span.num {
  display: block;
  font-size: 24px;
  font-weight: 600;
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title span.text {
  font-size: 16px;
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title:after {
  background-image: url('data:image/svg+xml;utf8,<svg width="37px" height="40px" viewBox="0 0 37 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch --><title>Page 1 Copy 2</title><desc>Created with Sketch.</desc><g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Stats-Copy-3" transform="translate(-878.000000, -474.000000)" fill="#E1E1E1"><g id="Group-14" transform="translate(321.000000, 375.000000)"><g id="Group-13" transform="translate(0.000000, 97.000000)"><g id="Page-1-Copy-2" transform="translate(557.000000, 2.000000)"><polygon id="Fill-1" points="17.1342 0.0004 12.1842 4.9494 26.7692 19.5354 12.1842 34.1214 17.1342 39.0704 36.6692 19.5354"></polygon><polygon id="Fill-2" points="0 11.8687 7.666 19.5347 0 27.2007 4.949 32.1497 17.566 19.5347 4.949 6.9197"></polygon></g></g></g></g></g></svg>');
  content: "";
  display: block;
  height: 40px;
  width: 37px;
  position: absolute;
  top: 8px;
  right: -18px;
  opacity: 0.4;
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title:last-child:after {
  display: none;
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title:nth-child(1) {
  color: #BB1614;
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title:nth-child(1):after {
  background-image: url('data:image/svg+xml;utf8,<svg width="37px" height="40px" viewBox="0 0 37 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch --><title>Page 1 Copy 2</title><desc>Created with Sketch.</desc><g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Stats-Copy-3" transform="translate(-878.000000, -474.000000)" fill="#BB1614"><g id="Group-14" transform="translate(321.000000, 375.000000)"><g id="Group-13" transform="translate(0.000000, 97.000000)"><g id="Page-1-Copy-2" transform="translate(557.000000, 2.000000)"><polygon id="Fill-1" points="17.1342 0.0004 12.1842 4.9494 26.7692 19.5354 12.1842 34.1214 17.1342 39.0704 36.6692 19.5354"></polygon><polygon id="Fill-2" points="0 11.8687 7.666 19.5347 0 27.2007 4.949 32.1497 17.566 19.5347 4.949 6.9197"></polygon></g></g></g></g></g></svg>');
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title:nth-child(2) {
  color: #E25B1E;
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title:nth-child(2):after {
  background-image: url('data:image/svg+xml;utf8,<svg width="37px" height="40px" viewBox="0 0 37 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch --><title>Page 1 Copy 2</title><desc>Created with Sketch.</desc><g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Stats-Copy-3" transform="translate(-878.000000, -474.000000)" fill="#E25B1E"><g id="Group-14" transform="translate(321.000000, 375.000000)"><g id="Group-13" transform="translate(0.000000, 97.000000)"><g id="Page-1-Copy-2" transform="translate(557.000000, 2.000000)"><polygon id="Fill-1" points="17.1342 0.0004 12.1842 4.9494 26.7692 19.5354 12.1842 34.1214 17.1342 39.0704 36.6692 19.5354"></polygon><polygon id="Fill-2" points="0 11.8687 7.666 19.5347 0 27.2007 4.949 32.1497 17.566 19.5347 4.949 6.9197"></polygon></g></g></g></g></g></svg>');
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title:nth-child(3) {
  color: #008B8C;
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title:nth-child(3):after {
  background-image: url('data:image/svg+xml;utf8,<svg width="37px" height="40px" viewBox="0 0 37 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch --><title>Page 1 Copy 2</title><desc>Created with Sketch.</desc><g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Stats-Copy-3" transform="translate(-878.000000, -474.000000)" fill="#008B8C"><g id="Group-14" transform="translate(321.000000, 375.000000)"><g id="Group-13" transform="translate(0.000000, 97.000000)"><g id="Page-1-Copy-2" transform="translate(557.000000, 2.000000)"><polygon id="Fill-1" points="17.1342 0.0004 12.1842 4.9494 26.7692 19.5354 12.1842 34.1214 17.1342 39.0704 36.6692 19.5354"></polygon><polygon id="Fill-2" points="0 11.8687 7.666 19.5347 0 27.2007 4.949 32.1497 17.566 19.5347 4.949 6.9197"></polygon></g></g></g></g></g></svg>');
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title:nth-child(4) {
  color: #4077d1;
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title:nth-child(4):after {
  background-image: url('data:image/svg+xml;utf8,<svg width="37px" height="40px" viewBox="0 0 37 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch --><title>Page 1 Copy 2</title><desc>Created with Sketch.</desc><g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Stats-Copy-3" transform="translate(-878.000000, -474.000000)" fill="#4077d1"><g id="Group-14" transform="translate(321.000000, 375.000000)"><g id="Group-13" transform="translate(0.000000, 97.000000)"><g id="Page-1-Copy-2" transform="translate(557.000000, 2.000000)"><polygon id="Fill-1" points="17.1342 0.0004 12.1842 4.9494 26.7692 19.5354 12.1842 34.1214 17.1342 39.0704 36.6692 19.5354"></polygon><polygon id="Fill-2" points="0 11.8687 7.666 19.5347 0 27.2007 4.949 32.1497 17.566 19.5347 4.949 6.9197"></polygon></g></g></g></g></g></svg>');
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title:nth-child(5) {
  color: #30538d;
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title:nth-child(5):after {
  background-image: url('data:image/svg+xml;utf8,<svg width="37px" height="40px" viewBox="0 0 37 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch --><title>Page 1 Copy 2</title><desc>Created with Sketch.</desc><g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Stats-Copy-3" transform="translate(-878.000000, -474.000000)" fill="#30538d"><g id="Group-14" transform="translate(321.000000, 375.000000)"><g id="Group-13" transform="translate(0.000000, 97.000000)"><g id="Page-1-Copy-2" transform="translate(557.000000, 2.000000)"><polygon id="Fill-1" points="17.1342 0.0004 12.1842 4.9494 26.7692 19.5354 12.1842 34.1214 17.1342 39.0704 36.6692 19.5354"></polygon><polygon id="Fill-2" points="0 11.8687 7.666 19.5347 0 27.2007 4.949 32.1497 17.566 19.5347 4.949 6.9197"></polygon></g></g></g></g></g></svg>');
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title:nth-child(6) {
  color: #131a8c;
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title:nth-child(6):after {
  background-image: url('data:image/svg+xml;utf8,<svg width="37px" height="40px" viewBox="0 0 37 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch --><title>Page 1 Copy 2</title><desc>Created with Sketch.</desc><g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Stats-Copy-3" transform="translate(-878.000000, -474.000000)" fill="#131a8c"><g id="Group-14" transform="translate(321.000000, 375.000000)"><g id="Group-13" transform="translate(0.000000, 97.000000)"><g id="Page-1-Copy-2" transform="translate(557.000000, 2.000000)"><polygon id="Fill-1" points="17.1342 0.0004 12.1842 4.9494 26.7692 19.5354 12.1842 34.1214 17.1342 39.0704 36.6692 19.5354"></polygon><polygon id="Fill-2" points="0 11.8687 7.666 19.5347 0 27.2007 4.949 32.1497 17.566 19.5347 4.949 6.9197"></polygon></g></g></g></g></g></svg>');
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title.current:after {
  background-image: url('data:image/svg+xml;utf8,<svg width="37px" height="40px" viewBox="0 0 37 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch --><title>Page 1 Copy 2</title><desc>Created with Sketch.</desc><g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Stats-Copy-3" transform="translate(-878.000000, -474.000000)" fill="#E1E1E1"><g id="Group-14" transform="translate(321.000000, 375.000000)"><g id="Group-13" transform="translate(0.000000, 97.000000)"><g id="Page-1-Copy-2" transform="translate(557.000000, 2.000000)"><polygon id="Fill-1" points="17.1342 0.0004 12.1842 4.9494 26.7692 19.5354 12.1842 34.1214 17.1342 39.0704 36.6692 19.5354"></polygon><polygon id="Fill-2" points="0 11.8687 7.666 19.5347 0 27.2007 4.949 32.1497 17.566 19.5347 4.949 6.9197"></polygon></g></g></g></g></g></svg>');
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title.nactive {
  color: #C7C7C7;
}

.profile-page-wrap .profile-container .profile-progress-wrap .progress-titles-wrap .progress-title.nactive:after {
  background-image: url('data:image/svg+xml;utf8,<svg width="37px" height="40px" viewBox="0 0 37 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch --><title>Page 1 Copy 2</title><desc>Created with Sketch.</desc><g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Stats-Copy-3" transform="translate(-878.000000, -474.000000)" fill="#E1E1E1"><g id="Group-14" transform="translate(321.000000, 375.000000)"><g id="Group-13" transform="translate(0.000000, 97.000000)"><g id="Page-1-Copy-2" transform="translate(557.000000, 2.000000)"><polygon id="Fill-1" points="17.1342 0.0004 12.1842 4.9494 26.7692 19.5354 12.1842 34.1214 17.1342 39.0704 36.6692 19.5354"></polygon><polygon id="Fill-2" points="0 11.8687 7.666 19.5347 0 27.2007 4.949 32.1497 17.566 19.5347 4.949 6.9197"></polygon></g></g></g></g></g></svg>');
}

.profile-page-wrap .profile-container .profile-bottom-wrap {
  overflow: hidden;
  padding-bottom: 60px;
  justify-content: space-between;
  display: flex;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-left-wrap {
  border-right: 1px solid #EBE9F1;
  padding-right: 30px;
  /*float: left;*/
  /*width: 70%;*/
  flex: auto 1 1;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-left-wrap .profile-activity {
  border-top: 1px solid #EBE9F1;
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 20px;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-left-wrap .profile-activity .profile-activity-head-wrap {
  overflow: hidden;
  margin-bottom: 36px;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-left-wrap .profile-activity .profile-activity-head-wrap .profile-activity-head {
  float: left;
  color: #404040;
  font-size: 28px;
  font-weight: 700;
  overflow: hidden;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-left-wrap .profile-activity .profile-activity-head-wrap .profile-activity-sorter {
  float: right;
  margin-top: 10px;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-left-wrap .profile-activity .profile-activity-head-wrap .profile-activity-sorter .activity-sort {
  color: #404040;
  float: left;
  font-size: 16px;
  margin-left: 30px;
  opacity: 0.4;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-left-wrap .profile-activity .profile-activity-head-wrap .profile-activity-sorter .activity-sort:hover {
  opacity: 0.6;
  cursor: pointer;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-left-wrap .profile-activity .profile-activity-head-wrap .profile-activity-sorter .activity-sort.active {
  opacity: 1;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-left-wrap .bottom-stats-wrap {
  text-align: center;
  padding-bottom: 30px;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-left-wrap .bottom-stats-wrap .stats-block {
  color: #404040;
  display: inline-block;
  font-size: 18px;
  padding-top: 15px;
  padding-bottom: 10px;
  vertical-align: top;
  width: 30%;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-left-wrap .bottom-stats-wrap .stats-block .num {
  color: #4689FE;
  display: block;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-left-wrap .bottom-stats-wrap .stats-block .stats-detail-link {
  color: #4589FD;
  font-size: 16px;
  text-decoration: underline;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-left-wrap .bottom-stats-wrap .stats-block .stats-detail-link:hover {
  text-decoration: none;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-right-wrap {
  /*float: left;*/
  padding-left: 30px;
  /*width: 30%;*/
  flex: 27% 0 0;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-right-wrap .current-course {
  max-width: 300px;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-right-wrap .current-course .current-course-head {
  color: #404040;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 36px;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-right-wrap .current-course .course-block {
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(59, 63, 98, 0.08);
  border-radius: 10px;
  padding-bottom: 30px;
  display: block;
  position: relative;
  margin-bottom: 30px;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  margin-right: 20px;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-right-wrap .current-course .course-block .course-progress {
  background: #fff;
  border-radius: 50%;
  position: absolute;
  height: 62px;
  width: 62px;
  top: -14px;
  right: -14px;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-right-wrap .current-course .course-block .course-progress canvas {
  margin-left: -1px;
  margin-top: -1px;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-right-wrap .current-course .course-block .course-pic {
  background: #FCFCFC;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-right-wrap .current-course .course-block .course-pic img {
  max-height: 200px;
  max-width: 100%;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-right-wrap .current-course .course-block .course-title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  display: block;
  padding: 0px 10px;
  text-align: center;
}

.profile-page-wrap .profile-container .profile-bottom-wrap .profile-right-wrap .current-course .course-block:hover {
  box-shadow: 0 6px 20px 0 rgba(59, 63, 98, 0.12);
}

.lecture {
  text-align: center;
}

.video-lecture {
  width: 640px;
}

.star-rating {
  line-height: 32px;
  font-size: 1.25em;
}

.star-rating .fa-star {
  color: red;
}

/*Модалка на странице ментора*/
.modal_div {
  width: 700px;
  height: 700px; /* Рaзмеры дoлжны быть фиксирoвaны */
  border-radius: 5px;
  border: 3px #000 solid;
  background: #fff;
  position: fixed; /* чтoбы oкнo былo в видимoй зoне в любoм месте */
  top: 25%; /* oтступaем сверху 45%, oстaльные 5% пoдвинет скрипт */
  left: 35%; /* пoлoвинa экрaнa слевa */
  margin-top: -150px;
  margin-left: -150px; /* тут вся мaгия центрoвки css, oтступaем влевo и вверх минус пoлoвину ширины и высoты сooтветственнo =) */
  display: none; /* в oбычнoм сoстoянии oкнa не дoлжнo быть */
  opacity: 0; /* пoлнoстью прoзрaчнo для aнимирoвaния */
  z-index: 5; /* oкнo дoлжнo быть нaибoлее бoльшем слoе */
  padding: 20px 10px;
}

/* Кнoпкa зaкрыть для тех ктo в тaнке) */
.modal_div .modal_close {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  display: block;
}

/* Пoдлoжкa */
#overlay {
  z-index: 3; /* пoдлoжкa дoлжнa быть выше слoев элементoв сaйтa, нo ниже слoя мoдaльнoгo oкнa */
  position: fixed; /* всегдa перекрывaет весь сaйт */
  background-color: #000; /* чернaя */
  opacity: 0.8; /* нo немнoгo прoзрaчнa */
  -moz-opacity: 0.8; /* фикс прозрачности для старых браузеров */
  filter: alpha(opacity=80);
  width: 100%;
  height: 100%; /* рaзмерoм вo весь экрaн */
  top: 0; /* сверху и слевa 0, oбязaтельные свoйствa! */
  left: 0;
  cursor: pointer;
  display: none; /* в oбычнoм сoстoянии её нет) */
}

.mentor-dialog-wrap .mentor-dialog-container .im-message {
  width: 50%;
  position: relative;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message .message-userpic {
  background: #fff;
  height: 44px;
  width: 44px;
  border: #fff 2px solid;
  border-radius: 50%;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message .message-userpic img {
  max-height: 44px;
  max-width: 44px;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message .message-ballon {
  border-radius: 10px;
  font-size: 16px;
  color: #404040;
  margin-bottom: 30px;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message .message-ballon .message-head {
  margin-bottom: 4px;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message .message-ballon .message-head .message-username {
  font-size: 18px;
  font-weight: 600;
  margin-right: 14px;
  opacity: 0.8;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message .message-ballon .message-head .message-time {
  opacity: 0.3;
  font-size: 15px;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message .message-ballon .message-text {
  font-size: 16px;
  word-wrap: break-word;
}

.out-message .message-ballon .message-mentor-stars {
  margin-bottom: 6px;
}

.out-message .message-ballon .message-mentor-stars span {
  color: rgba(127, 180, 208, 0.5);
  cursor: pointer;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.out-message .message-ballon .message-mentor-stars span:hover {
  color: #7fb4d0;
}

.out-message .message-ballon .message-mentor-stars.star-1 span:nth-child(1) {
  color: #7fb4d0;
}

.out-message .message-ballon .message-mentor-stars.star-2 span:nth-child(1) {
  color: #7fb4d0;
}

.out-message .message-ballon .message-mentor-stars.star-2 span:nth-child(2) {
  color: #7fb4d0;
}

.out-message .message-ballon .message-mentor-stars.star-3 span:nth-child(1) {
  color: #7fb4d0;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message .message-ballon .message-mentor-stars.star-3 span:nth-child(2) {
  color: #7fb4d0;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message .message-ballon .message-mentor-stars.star-3 span:nth-child(3) {
  color: #7fb4d0;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message:first-child {
  margin-top: 40px;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message.message-in {
  float: left;
  clear: both;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message.message-in .message-userpic {
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  max-height: 38px;
  max-width: 38px;
  overflow: hidden;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message.message-in .message-ballon {
  background: #EBF6FC;
  padding: 15px 20px 20px 30px;
  margin-left: 20px;
  margin-top: 20px;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message.message-out {
  float: right;
  clear: both;
  margin-right: 20px;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message.message-out .message-userpic {
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 50%;
  max-height: 38px;
  max-width: 38px;
  overflow: hidden;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message.message-out .message-ballon {
  background: #F6F6F6;
  padding: 15px 30px 20px 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message.message-out {
  width: 70%;
}

.mentor-dialog-wrap .mentor-dialog-container .im-message.message-in {
  width: 70%;
}

.switch {
  font-size: 1rem;
  position: relative;
}

.switch input {
  position: absolute;
  height: 1px;
  width: 1px;
  background: none;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  padding: 0;
}

.switch input + label {
  position: relative;
  min-width: calc(calc(2.375rem * .8) * 2);
  border-radius: calc(2.375rem * .8);
  height: calc(2.375rem * .8);
  line-height: calc(2.375rem * .8);
  display: inline-block;
  cursor: pointer;
  outline: none;
  user-select: none;
  vertical-align: middle;
  text-indent: calc(calc(calc(2.375rem * .8) * 2) + .5rem);
}

.switch input + label::before,
.switch input + label::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: calc(calc(2.375rem * .8) * 2);
  bottom: 0;
  display: block;
}

.switch input + label::before {
  right: 0;
  background-color: #dee2e6;
  border-radius: calc(2.375rem * .8);
  transition: 0.2s all;
}

.switch input + label::after {
  top: 2px;
  left: 2px;
  width: calc(calc(2.375rem * .8) - calc(2px * 2));
  height: calc(calc(2.375rem * .8) - calc(2px * 2));
  border-radius: 50%;
  background-color: white;
  transition: 0.2s all;
}

.switch input:checked + label::before {
  background-color: #08d;
}

.switch input:checked + label::after {
  margin-left: calc(2.375rem * .8);
}

.switch input:focus + label::before {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 136, 221, 0.25);
}

.switch input:disabled + label {
  color: #868e96;
  cursor: not-allowed;
}

.switch input:disabled + label::before {
  background-color: #e9ecef;
}

.switch.switch-sm {
  font-size: 0.875rem;
}

.switch.switch-sm input + label {
  min-width: calc(calc(1.9375rem * .8) * 2);
  height: calc(1.9375rem * .8);
  line-height: calc(1.9375rem * .8);
  text-indent: calc(calc(calc(1.9375rem * .8) * 2) + .5rem);
}

.switch.switch-sm input + label::before {
  width: calc(calc(1.9375rem * .8) * 2);
}

.switch.switch-sm input + label::after {
  width: calc(calc(1.9375rem * .8) - calc(2px * 2));
  height: calc(calc(1.9375rem * .8) - calc(2px * 2));
}

.switch.switch-sm input:checked + label::after {
  margin-left: calc(1.9375rem * .8);
}

.switch.switch-lg {
  font-size: 1.25rem;
}

.switch.switch-lg input + label {
  min-width: calc(calc(3rem * .8) * 2);
  height: calc(3rem * .8);
  line-height: calc(3rem * .8);
  text-indent: calc(calc(calc(3rem * .8) * 2) + .5rem);
}

.switch.switch-lg input + label::before {
  width: calc(calc(3rem * .8) * 2);
}

.switch.switch-lg input + label::after {
  width: calc(calc(3rem * .8) - calc(2px * 2));
  height: calc(calc(3rem * .8) - calc(2px * 2));
}

.switch.switch-lg input:checked + label::after {
  margin-left: calc(3rem * .8);
}

.switch + .switch {
  margin-left: 1rem;
}

.edit-password-content {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.noty_layout {
  z-index: 1;
  position: fixed !important;
  left: 30px;
  top: 65px !important;
}

.noty_bar {
  min-width: 20px;
  padding: 0px 34px;
  text-align: center;
  margin-bottom: 4px !important;
}

.noty_theme__sunset.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  border-radius: 2px;
  position: relative;
}

.noty_theme__sunset.noty_bar .noty_body {
  padding: 10px;
  font-size: 14px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.noty_theme__sunset.noty_bar .noty_buttons {
  padding: 10px;
}

.noty_theme__sunset.noty_type__alert,
.noty_theme__sunset.noty_type__notification {
  background-color: #d81b47;
  color: #fff;
}

.noty_theme__sunset.noty_type__alert .noty_progressbar,
.noty_theme__sunset.noty_type__notification .noty_progressbar {
  background-color: #fff;
}

.noty_theme__sunset.noty_type__warning {
  background-color: #f8c03c;
  color: #fff;
}

.noty_theme__sunset.noty_type__error {
  background-color: #EF476F;
  color: #fff;
}

.noty_theme__sunset.noty_type__error .noty_progressbar {
  opacity: .4;
}

.noty_theme__sunset.noty_type__info,
.noty_theme__sunset.noty_type__information {
  background-color: #0d9fd0;
  color: #fff;
}

.noty_theme__sunset.noty_type__info .noty_progressbar,
.noty_theme__sunset.noty_type__information .noty_progressbar {
  opacity: .6;
}

.noty_theme__sunset.noty_type__success {
  background-color: #06d65e;
  color: #fff;
}

.modal-message-head {
  font-size: 30px;
  color: #404040;
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px;
}

.message-to-comment-popup {
  max-width: 600px;
}

.message-to-comment {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.button-a-message-to-comment {
  cursor: pointer;
  border-bottom: 1px dashed #BCBCBC;
  color: #BCBCBC;
  margin-bottom: 1rem;
  font-size: 25px;
  -webkit-transition: all 200ms;
}

.content-buttom-message-to-comment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/**/

.message-to-support-popup {
  max-width: 600px;
}

.message-to-support {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.button-a-message-to-support {
  cursor: pointer;
  border-bottom: 1px dashed #BCBCBC;
  color: #BCBCBC;
  font-size: 18px;
  float: left;
  margin-top: 5px;
  -webkit-transition: all 200ms;
}

.content-buttom-message-to-support {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-bottom-wrapper .all-comments-wrap .comment-block .answer-block {
  margin-top: 2rem;
  margin-left: 44px;
  border-top: 2px solid #e7e8ec;
  border-bottom: 2px solid #e7e8ec;
  border-left-style: none;
  border-right-style: none;
}

.cursor-pointer {
  cursor: pointer;
}

#mentor-name {
  color: #0f0f0f;
  float: right;
  margin-top: -15px;
  font-size: large;
  text-decoration: underline;
}

.task-content__mentor-comment-block {
  padding: 12px 24px;
  border-radius: 4px;
}

.task-content__mentor-comment-failed {
  color: #404040;
  background-color: #ffb1b1;
  border-color: #ffb1b1;
}

.task-content__mentor-comment-success {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.task-content__mentor-comment-header {
  margin: 0;
}

.task-content__mentor-comment-body {
  margin: 6px 0 0 0;
}

/*.react-calendar-heatmap .color-github-14 {*/
/*    fill: #238df8;*/
/*}*/

/*.react-calendar-heatmap .color-github-13 {*/
/*    fill: #3496f8;*/
/*}*/

/*.react-calendar-heatmap .color-github-12 {*/
/*    fill: #429df8;*/
/*}*/

/*.react-calendar-heatmap .color-github-11 {*/
/*    fill: #50a4f9;*/
/*}*/

/*.react-calendar-heatmap .color-github-10 {*/
/*    fill: #5eacf9;*/
/*}*/

/*.react-calendar-heatmap .color-github-9 {*/
/*    fill: #6eb4f9;*/
/*}*/

/*.react-calendar-heatmap .color-github-8 {*/
/*    fill: #7dbcfa;*/
/*}*/

/*.react-calendar-heatmap .color-github-7 {*/
/*    fill: #8bc3f9;*/
/*}*/

/*.react-calendar-heatmap .color-github-6 {*/
/*    fill: #9bcbfa;*/
/*}*/

/*.react-calendar-heatmap .color-github-5 {*/
/*    fill: #a6d1f9;*/
/*}*/

/*.react-calendar-heatmap .color-github-4 {*/
/*    fill: #b7dafb;*/
/*}*/

/*.react-calendar-heatmap .color-github-3 {*/
/*    fill: #c7e2fa;*/
/*}*/

/*.react-calendar-heatmap .color-github-2 {*/
/*    fill: #d2e7fb;*/
/*}*/

/*.react-calendar-heatmap .color-github-1 {*/
/*    fill: #dfeefb;*/
/*}*/

/*.react-calendar-heatmap .color-github-0 {*/
/*    fill: #EFF1FC;*/
/*}*/

.fancybox-close-small:after {
  content: "×";
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  font: 20px/30px Arial, Helvetica Neue, Helvetica, sans-serif;
  color: #888;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  background: #fff;
  transition: background .25s;
  box-sizing: border-box;
  z-index: 2;
}

.fancybox-close-small {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  background: transparent;
  z-index: 10;
  cursor: pointer;
}

.info-jm-block {
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(83, 83, 83, 0.08);
  background: #fff;
  padding: 30px;
  margin: 100px auto auto;
  width: 80%;
  overflow: hidden;
}

.info-jm-content {
  display: flex;
  flex-direction: column;
  margin-left: 10rem;
}

.info-jm-block h3 {
  color: #404040;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 18px;
}

.task-loader {
  display: flex;
  margin: auto;
  justify-content: center;
}

.emoji-wysiwyg-editor {
  border: 1px solid #d0d0d0;
  height: 100px;
  overflow: auto;
  outline: none;
}

.emoji-wysiwyg-editor img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin: -3px 0 0 0;
}

.comment-block-wrap {
  position: relative;
}

.comments-student-action-panel {
  display: flex !important;
  flex-direction: row;
}

.comments-student-action-panel * {
  cursor: pointer;
}

.comment-interaction {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  color: #337ab7;
}

.text-area-comment {
  background: #fff;
  border-radius: 6px;
  border: 1px solid #E6E4EE;
  color: #404040;
  min-height: 124px;
  resize: vertical;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  outline: none;
  width: 100%;
}

.depend-comment-button {
  display: flex;
  margin-left: auto;
  cursor: pointer;
  color: #337ab7;
  text-decoration: none;
}

.comment-like-block {
  cursor: pointer;
  color: #337ab7
}

.non-clickable {
  pointer-events: none;
}
