$headerOnHoverColor: #337ab7;
.header-sort-btn{
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;

  &:hover{
    color: $headerOnHoverColor;    
  }
}

.header-sort-btn i {
  margin-left: 2px;
}