.project-queue-modal {
  &__info {
    margin: 0;
    padding: 0;

  }
  &__user {
    margin: 20px 0;
    padding: 0;
  }

  &__username {
    font-weight: bold;
  }
}
