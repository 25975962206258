@import "../constants";

.report-calendar-wrapper {
    background: rgba($mainBlue, 0.2);
}

.report-calendar {
  position: relative;

  &__btn {
    position: absolute;
    top: 25%;
    color: $mainBlue;
    background: transparent;
    border-radius: 5px;
    width: 2rem;
    height: 2rem;
    border: 2px solid currentColor;

    &:disabled {
      color: $firsGray;
    }
  }
  &__prev-btn {
    left: -4%;

    &:after {
      content: '';
      position: absolute;
      display: block;
      height: 120%;
      width: 1px;
      background: rgba(0, 0, 0, 0.15);
      top: -10%;
      right: -80%;
    }
  }
  &__next-btn {
    right: -4%;
  }
}

.slide {
  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;

    &:after {
      content: '';
      width: 1px;
      height: 50%;
      position: absolute;
      background: rgba(0, 0, 0, 0.15);
      right: 0;
    }
  }

  &__day {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    text-transform: capitalize;
    transition: all .2s ease-in;
    padding: 5px 0;

    &--disabled {
      color: $firsGray;
      pointer-events: none;
      cursor: not-allowed;;
    }
  }

  &__month{
    font-size: 0.7rem;
  }

  &__day {
    font-size: 1.2rem;
    margin: 0;
  }
}


.report-list {
    margin-top: 20px ;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 20px;
    position: relative;
  }
  
  .report-not-found {
    display: flex;
    min-height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .report-message {
    background: #e5ecf7;
    padding: 15px 30px;
    border-radius: 5px;
  }
  
  .report-empty {
    min-height: 210px;
    width: calc(100% / 5);
    background: #F6F6F6;
    box-sizing: border-box;
    padding: 25px;
  }
  
  .report-grid {
    display: grid;
    gap: 6px;
    overflow: hidden;
    margin: 20px 0;
  }
  
  .report-row {
    display: flex;
    align-items: flex-start;
    gap: 6px;
  }
  
  .report-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 210px;
    width: calc(100% / 5);
    background: #FFFFFF;
    border: 1px solid $firsGray;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px;
    transition: .02s ease-in;
    &:hover{
      border-color: $mainBlue;
    }
  }
  
  .card-avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
  
  .card-img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    object-position: 50% 50%;
  }
  
  .student-name {
    font-size: 20px;
    margin-top: 15px;
  }
  
  .report-data {
    margin-left: 10px;
    width: auto;
  }
  
  .student-birthday {
    font-size: 13px;
    color: #747474;
  }
  
  .spend-time, .task-progress-text {
    margin-top: 15px;
    font-size: 17px;
    text-overflow: ellipsis;
  }
  
  .spend-light-text {
    font-weight: lighter;
    margin-left: 10px;
  }
  
  .task-progress-text {
    max-height: 80px;
    overflow: hidden;
    word-wrap:anywhere ;
  
  }
  .spend-time{
    margin-top: 15px;
    display: flex;
  }
  
  .spend-text {
    align-self: end;
  }
  
  .task-progress-text--expand_true{
    max-height: 100% !important;
  }
  
  .task-progress-text--gradient {
    position: relative;
  
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      background:linear-gradient(transparent 50px, white);
    }
  }
  
  .toggle-expand-text{
    padding: 0;
    border: none;
    font: inherit;
    background-color: transparent;
    cursor: pointer;
    font-size: 17px;
    font-weight: 700;
    color:$mainBlue;
    transition: color 0.2s ease-in;
    &:hover{
      text-decoration: underline;
    }
  }
  
  .edit-menu-btn{
    position: absolute;
    font-size: 19px;
    right: 15px;
  }
  .edit-buttons{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: white;
    border-radius: 10px;
    row-gap: 5px;
    font-size: 13px;
  }
  .edit-btn{
    display: flex;
    justify-content: flex-start;
    transition: color .2s ease-in;
    &:hover{
      color: $mainBlue;
    }
  }
  .btn{
    padding: 0;
    border: none;
    font: inherit;
    background-color: transparent;
    cursor: pointer;
  }
  