.header{
  display: flex;
  flex-direction: row;
  min-height: 65px;
  width: 100%;
  background: #e4e4e4;
  border: 1px solid #e4e4e4;
  padding: 0 10px 0 10px;
}
.title{
  line-height: 19px;
  margin: auto;
}
