.row-options {
  display: flex ;
  justify-content: space-between;
  align-items: center;
}
.col-options {
  display: flex;
}

.Calendar {
  font-size: 8px !important  /* уменьшил шрифт для календаря react-modern-calendar-datepicker */
}