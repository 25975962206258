.logo-block {
  border: 1px solid #b5b5b5;
  border-radius: 3px;
  text-align: center;
  padding: 20px;
}

.react-tabs__tab-list {
  margin-bottom: 0;
  border-bottom: initial;
}
