.formField {
  display: flex;
  flex-direction: column;
  width: 8.75rem;
  margin-bottom: 1rem;

  .formControl {
    width: 2.5rem;
  }
}
