.reviewQuestionsHeader {
  display: flex;
  margin-left: auto;
  align-items: center;
}

button.addQuestionButton {
  width: 120px;
  height: 45px;
  margin-left: 1rem;
}

select.moduleIdSelect {
  width: 200px;
  margin-right: 1rem;
}

select.courseIdSelect {
  width: 200px;
  margin-right: 1rem;
}

select.directionSelect {
  width: 200px;
  margin-right: 1rem;
}
