$dark-color: rgb(20, 20, 20);
$grey-color: #C8C8C8;
$grey-light-color: #E4E4E4;

.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &-wrapper {
    z-index: 2;
    border: 1px solid $grey-color;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    min-width: 600px;
    min-height: 150px;
    background: white;
  }

  &-header {
    text-align: center;
    position: relative;
    background-color: $grey-light-color;
    color: black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &-title {
    margin: 0;
    padding: 15px 0;
    font-size: 20px;
  }

  &-close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    cursor: pointer;
    transition: opacity .2s ease-out;
  }

  &-close:hover {
    opacity: 1;
  }

  &-close:before, &-close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 25px;
    width: 2px;
    background-color: #333;
  }

  &-close:before {
    transform: rotate(45deg);
  }

  &-close:after {
    transform: rotate(-45deg);
  }

  &-container {
    padding: 30px;
  }
  
  // &-content {
  // }

}