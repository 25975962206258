.review-questions-list-action {
  float: right;
}

.edit-review-question {
  margin-right: 5px;
  cursor: pointer;
}

.question div {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
