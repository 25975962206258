
ol.student-debts-list > li {
  width: 400px;
  background-color: rgba(211, 211, 211, 0.5);
  border-radius: 5px;
  margin: 0 0 1em 3em;
  padding: 7px 20px;
  position: relative;
}

.no-revoke-debt {
  background: rgba(255, 0, 0, 0.1) !important;
}

.check-no-revoke-debt {
  background: #ff0e2b !important;
}

.check-revoke-debt {
  background: rgba(1, 213, 54, 0.1) !important;
}


.revoke-debt {
  display: flex;
  float: right;
  cursor: pointer;
  font-size: 15px;
  background: #01D536;
  border: 1px solid #555555;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  margin: 3px;
}

.theory-review-debts-box p {
  width: 85%;
}

.revoke-debt .mdi-check-outline {
  margin: auto;
  color: white;
}


.revoke-debt:hover {
  font-size: 17px;
}

.revoke-debt .mdi-close-outline {
  margin: auto;
  color: white;
}


