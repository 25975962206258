.task-desc button {
  width: 100%;
}

.task-desc {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.task-desc-content {
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: left;
  white-space: pre-wrap !important;
}

.task-desc-content pre {
  max-width: 60rem;
}
