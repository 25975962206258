.actives {
  background-color: #a6d6ff !important;
  /*float: left !important;*/
}

.list-group-item {
  cursor: pointer;
  &.current {
    cursor: initial;
    opacity: 0.4;
  }
}

.list-group-item > i {
  margin-right: 10%;
  margin-left: auto;
}
