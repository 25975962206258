.gappingTaskForm {

  margin-top: 20px;

  .taskTextInputBlock {
    margin-bottom: 20px;
    .taskTextInput {
      resize: vertical;
    }
  }

  .gappingItemsBlock {
    margin-bottom: 20px;

    .addItemBlock {
      display: flex;
      flex-direction: column;
      justify-content: center;
      button {
        text-decoration: underline;
      }
    }
  }

  .textPreview {
    margin-bottom: 20px;
    margin-top: 20px;
    max-width: 100%;
    .usualText {
      color: #727272;
      white-space: pre-wrap;
    }
    .expectedText {
      text-decoration: black underline;
      font-weight: 900;
      white-space: pre-wrap;
    }
  }

}
