.count {
  display: inline-block;
  transform: translateY(-15%);
  right: 20px;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  font-size: 11px;
  color: #fff;
  background-color: red;
  border-radius: 50%;
}
