.code-review-tasks-list {
  margin: auto;
  display: flex;
  justify-content: center;
}

.student-task {
  display: flex;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  margin-left: 4px;
  margin-top: 1rem;
  cursor: pointer;
}

.student-task * {
  margin: auto;
}

.need_review {
  background: orange;
}

.need_next_review {
  background: red;
}

.full_completed {
  background: green;
}

.reviewed_now {
  background: green;
  opacity: .5;
}

.reviewed_now_with_comment {
  background: red;
  opacity: .5;
}

.greenClassName {
  background: green;
}

.code-task-row {
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  margin-left: 10rem;
}

.code-review-tasks-list-wrapper {
  display: flex;
  flex-direction: row;
}

.current-task {
  border: 4px solid #ffc34f;
}

.area-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

