.review-questions-header {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.review-questions-container {
  display: block;
}

.review-questions-field {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.review-question-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review-questions-button {
  align-self: end;
}

#add-question-button {
  margin-top: 12px;
  width: 120px;
  height: 45px;
  margin-right: 1rem;
}

#module-id-select {
  width: 200px;
  margin-right: 1rem;
  margin-top: 1rem;
}

#course-id-select {
  width: 200px;
  margin-right: 1rem;
  margin-top: 1rem;
}
