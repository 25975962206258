

.search-input {
    max-width: 405px;
  }

  .search-wrap {
    width: 100%;
    vertical-align: middle;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
  }
  


.search {
    width: 90%;
    height: 34px;
    border: 1px solid gray;
    box-sizing: border-box;
    font-size: 16px;
    padding-left: 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    transition: border 0.2s ease-in;
    &:hover{
      border: 1px solid blue;
    }
  }


.search-icon {
    margin: 5px;
  }

