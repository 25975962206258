#input-new-probably-answer {
  margin: 0;
}

.ordering-task-editor .list-group .list-group-item .input-group-addon,
.theory-task-editor .list-group .list-group-item .input-group-addon {
  border: none;
  background-color: inherit;
}


.theory-task-editor button[data-toggle="button"] {
  min-width: 100px;
}

.theory-task-editor button[data-toggle="button"].active {
  background-color: lightgreen;
  color: black;
}

.theory-task-editor button[data-toggle="button"].active:after {
  content: "Yes";
}

.theory-task-editor button[data-toggle="button"] {
  background-color: indianred;
  color: white;
}

.theory-task-editor button[data-toggle="button"]:after {
  content: "No";
}
