#course-id-select {
  width: 200px;
  margin-right: 1rem;
  margin-top: 1rem;
}

#add-course-button {
  width: 150px;
  height: 34px;
}

.my_target {
  background-color: #5bff59
}
