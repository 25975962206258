#chief-review-table-container {
  display: flex;
  width: 95%;
  justify-content: center;
  margin: 30px auto;
  overflow:auto;
  max-height: 600px;
  height: 100%;
  border-radius: 10px;
}

#chief-review-table tbody tr:nth-child(odd) {
  background: #F3F3F3;
}

#chief-review-table tbody tr:nth-child(even) {
  background: #e7e7e7;
}

#chief-review-table {
  font-size: 14px;
  margin: 0;
  width: 100%;
}


tr.chief-review-table-header td {
  color: white;
  font-size: 110%;
}

#chief-review-table thead tr {
  height: 50px;
  background: #CDDFFF;
}

#chief-review-table tfoot tr {
  height: 50px;
  background: #CDDFFF;
}

#chief-review-table td {
  border: 1px solid #C4C4C4;
  max-width: 95px;
}

#chief-review-table tr {
  text-align: center !important;
  border: 1px solid #CCCCCC;
}

#chief-review-table thead th {
  text-align: center;
  border: 1px solid #CCCCCC;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background: #CDDFFF;
}

#chief-review-table-footer-elem {
  display: flex;
  margin: auto;
}

#chief-review-table-footer-elem {
  display: flex;
  border: none !important;
  width: 100%;
  height: 100%;
}

#chief-review-table-footer-elem > * {
  margin: 10px auto auto;
}

#close-input-time-button {
  color: red;
  border-radius: unset;
  background-color: #CDDFFF;
  margin: auto;
}

#add-chief-review-table-time-input {
  margin: auto;
}
