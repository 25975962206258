.admin-excerptions-table th, td {
  text-align: center;
}

#add-excerptions-button {
  width: 200px;
  height: 40px;
  margin-top: 1rem;
}

.admin-excerptions-table {
  margin-top: 1rem;
}

.excerptions-text {
  text-align: start;
}
