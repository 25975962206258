.table-mentors {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 1fr;
  width: 301px;
  margin: 20px 0 30px 70px;
  border: 1px #CCCCCC solid;
  border-radius: 10px;
}

.table-row {
  display: grid;
  grid-template-columns: 200px 100px;
}

.col-mentor {
  text-align: left;
  border-right: 1px #CCCCCC solid;
  border-top: 1px #CCCCCC solid;
  padding-left: 5px;
}

.header-col.mentor {
  border-right: 1px #CCCCCC solid;
}

.header-col {
  font-size: 16px;
  font-weight: bold;
}

.footer-col {
  font-size: 16px;
  font-weight: bold;
}

.col-count {
  border-top: 1px #CCCCCC solid;
}

.footer-col.mentor {
  border-right: 1px #CCCCCC solid;
  border-top: 1px #CCCCCC solid;
  padding-left: 5px;
}

.footer-col.count {
  border-top: 1px #CCCCCC solid;
}
