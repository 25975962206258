.chief-review-action-panel {
    display: flex;
    flex-direction: row;
    padding: 20px;
}

#chief-review-period-label {
    font-size: 20px;
    text-decoration: underline;
    margin-left: 35px;
}

#chief-review-action-button {
    margin-left: 20px;
}
