.mentor-review-questions {
  margin: 4rem 6rem;
  min-width: 800px;
  width: min-content;
  display: flex;
  flex-direction: column;
  counter-reset: li;
  list-style: none;
  word-break: break-all;
  /* margin: 0; */
}

.mentor-review-questions p {
  word-break: break-all;
  white-space: pre-wrap;
  text-align: left;
  padding-right: 16px;
}

.list-question {
  counter-reset: mentor-review-questions;
  list-style: none;
}

.question {
  position: relative;
}

.modal-section {
  flex: 4;
  display: flex;
  flex-direction: column;
}

.mentor-footer {
  flex: 1;
  max-height: 50px;
}


#students-table-header {
  font-size: 28px;
  margin: auto;
}

.question-text-header {
  margin: 5px auto 0 auto;
}

#question-area {
  resize: vertical;
  height: 200px;
  max-height: 400px;
  padding: 20px;
  margin: 0 10px 5px 10px;
  border-radius: 5px;
}

#answer-area {
  resize: vertical;
  height: 200px;
  max-height: 400px;
  padding: 20px;
  margin: 0 10px 5px 10px;
  border-radius: 5px;
}

#id-question {
  visibility: hidden;
}
