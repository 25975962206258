.moder-comment-text {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  word-break: break-all;
  text-align: left;
  height: 9em;
}

.moder-comment-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 9em;
}
