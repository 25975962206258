.react-select--editable {
  margin-top: 10px;
  margin-bottom: 10px;
}
.react-select--editable .react-select__control{
  max-height: 34px ;
  min-height: 34px ;
  border-color: #ccc;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  &--is-focused{
    box-shadow:  0 0 8px rgb(102, 175, 233);
    border-color: #66afe9;
  }
}
.react-select--editable .react-select__placeholder{
  top: 45%;
}
.react-select--editable .react-select__menu{
   margin-top: 0;
}.react-select--editable .react-select__menu .react-select__option{
   height: 24px;
   font-size: 14px;
   padding: 4px 12px;
}
.user-form-select__error-message{
  color:#ff0000;
}
