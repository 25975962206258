.page {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: auto;
  background-color: #edeef0;
}

.header {
  width: 100%;
  height: 115px;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: #000;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  // width: 100%;
  height: 115px;
  padding-left: 65px;
  padding-right: 25px;
  background-color: #AFAFAF;
}

.headerStudent {
  display: flex;
  align-items: center;
  min-width: 400px;

  &::before {
    content: "";
    width: 44px;
    height: 28px;
    margin-right: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='44' height='28' viewBox='0 0 44 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M40 0V28H44V0H40ZM32 28H36V0H32V28ZM26 0H2C0.9 0 0 0.9 0 2V26C0 27.1 0.9 28 2 28H26C27.1 28 28 27.1 28 26V2C28 0.9 27.1 0 26 0ZM14 5.5C16.48 5.5 18.5 7.52 18.5 10C18.5 12.48 16.48 14.5 14 14.5C11.52 14.5 9.5 12.48 9.5 10C9.5 7.52 11.52 5.5 14 5.5ZM23 24H5V22.5C5 19.5 11 18 14 18C17 18 23 19.5 23 22.5V24Z' fill='%236D6D6D'/%3E%3C/svg%3E%0A");
  }
}

.headerStudentsList {
  display: flex;
  align-items: center;
  min-width: 400px;

  &::before {
    content: "";
    width: 41px;
    height: 40px;
    margin-right: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='41' height='40' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.98 0C9.93998 0 0.97998 8.96 0.97998 20C0.97998 31.04 9.93998 40 20.98 40C32.02 40 40.98 31.04 40.98 20C40.98 8.96 32.02 0 20.98 0ZM28.2 12.68C30.34 12.68 32.06 14.4 32.06 16.54C32.06 18.68 30.34 20.4 28.2 20.4C26.06 20.4 24.34 18.68 24.34 16.54C24.32 14.4 26.06 12.68 28.2 12.68ZM16.2 9.52C18.8 9.52 20.92 11.64 20.92 14.24C20.92 16.84 18.8 18.96 16.2 18.96C13.6 18.96 11.48 16.84 11.48 14.24C11.48 11.62 13.58 9.52 16.2 9.52ZM16.2 27.78V35.28C11.4 33.78 7.59998 30.08 5.91998 25.36C8.01998 23.12 13.26 21.98 16.2 21.98C17.26 21.98 18.6 22.14 20 22.42C16.72 24.16 16.2 26.46 16.2 27.78ZM20.98 36C20.44 36 19.92 35.98 19.4 35.92V27.78C19.4 24.94 25.28 23.52 28.2 23.52C30.34 23.52 34.04 24.3 35.88 25.82C33.54 31.76 27.76 36 20.98 36Z' fill='%236D6D6D'/%3E%3C/svg%3E%0A");
  }
}

.headerQuestions {
  display: flex;
  flex-direction: column;
}

.allQuestionsBtn,
.newQuestionBtn {
  border: none;
  background-color: transparent;
}

.allQuestionsBtn {
  width: 35px;
  height: 20px;
  margin-right: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='36' height='20' viewBox='0 0 36 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 12H4V8H0V12ZM0 20H4V16H0V20ZM0 4H4V0H0V4ZM8 12H36V8H8V12ZM8 20H36V16H8V20ZM8 0V4H36V0H8Z' fill='%236D6D6D'/%3E%3C/svg%3E%0A");
  transition: transform 0.1s linear;

  &:hover {
    transform: scale(1.1);
  }
}

.newQuestionBtn {
  width: 28px;
  height: 28px;
  margin-left: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 0.66C6.639 0.6 0.6 6.6 0.6 14C0.6 21.36 6.6 27.3 14 27.3C21.36 27.3 27.3 21.36 27.3 14C27.3333 6.64 21.36 0.6 14 0.6ZM2.6666 15.3H15.3V20.6H12.6V15.3333H7.3V12.6H12.6V7.3H15.3333V12.6667H20.6V15.3Z' fill='%236D6D6D'/%3E%3C/svg%3E%0A");
  transition: transform 0.1s linear;

  &:hover {
    transform: scale(1.1);
  }
}

.headerQuestionsTop {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.headerQuestionsBottom {
  & > div {
    margin-left: 0;
    right: 0;
    width: auto;
  }
  & input, & select {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.main {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  width: fit-content;
  min-height: calc(100vh - 115px);
  position: relative;
  padding: 35px 30px 60px 65px;
  background-color: #edeef0;

  & ol > li {
    margin: 0 0 1em 3em;
    padding: 7px 10px;
    background: #D3D3D3;
    border: 1px solid #787878;
    position: relative;
    border-radius: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }


  & ol > li::before {
    background: lightskyblue;
    font-size: 14px;
    border-radius: 50%;
    height: 22px;
    left: -40px;
    line-height: 22px;
    padding: 1px;
    position: absolute;
    text-align: center;
    top: 4px;
    transition: all 0.3s ease 0s;
    width: 22px;
  }

  & ol {
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  & li {
    background: #D3D3D3;
    width: 350px;
    cursor: pointer;
  }

  & p {
    word-break: break-word;
    text-align: left;
  }

  // & ::-webkit-scrollbar {
  //   width: 0;
  // }
}
