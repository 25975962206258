.search-input {
  margin: 0;
}

.add-btn {
  margin: 15px 0;
  width: 200px;
  position: center;
}

.code-task-table th, td {
  text-align: center;
}

.code-task-table {
  margin-top: 1rem;
}

.admin-code-description {
  width: 60rem;
}

.admin-code-controls {
  white-space: nowrap;
}

.task-desc button {
  height: 40px;
}
