.modal-section {
  overflow-y: auto;
}

.solutions-list {
  margin-top: 30px;
}

.solutions-list table {
  border: 1px solid #EAEBF4;
  width: 100%;
}

.solutions-list table tr {
  border-bottom: 1px solid #EAEBF4;
}

.solutions-list table tr td {
  padding: 9px 15px;
  width: 50%;
  text-align: left;
}

.solutions-list table tr td a {
  color: #679FFF;
  font-size: 16px;
}

.solutions-list table tr td a i {
  font-size: 22px;
  vertical-align: middle;
  margin-right: 4px;
}

.solutions-list table tr td a i.mdi-check {
  color: #00D134;
}

.solutions-list table tr td a i.mdi-close {
  color: #FF264D;
}

.solutions-list table tr td a span {
  border-bottom: 1px dashed rgba(103, 159, 255, 0.5);
  transition: all 200ms;
}

.solutions-list table tr td a:hover span {
  border-bottom: 1px dashed #679fff;
}

.solutions-list table tr td:nth-child(2) {
  color: rgba(64, 64, 64, 0.6);
  font-size: 16px;
  padding-left: 18px;
  text-align: left;
}
