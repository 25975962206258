.finish-review {
  display: flex;
  height: 50px;
  width: 250px;
}

a.finish-review {
  display: inline-block;
  padding: .5rem 4rem;
  color: white;
  font-size: 20px;
  border-width: 2px 0;
  border-style: solid none;
  border-color: #FDBE33 #000 #D77206;
  border-radius: 6px;
  background: linear-gradient(#F3AE0F, #E38916) #E38916;
  transition: 0.2s;

}


a.finish-review:hover {
  background: linear-gradient(#f5ae00, #f59500) #f5ae00;
}

a.finish-review:active {
  background: linear-gradient(#f59500, #f5ae00) #f59500;
}

.theory-review-button-container {
  display: flex;
  position: absolute;
  bottom: 60px;
  right: 30%
}

.theory-review-button-container * {
  margin-right: 4rem;
}
