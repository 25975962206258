.directions {
  width: 100%;
  height: 100%;
  padding: 15px;
}

.directions-title {
  text-align: center;
  margin-bottom: 23px;
}

.directions-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.directions-amount,
.directions-action {
  flex: 1
}

.directions-action {
  display: flex;
  justify-content: center;
  align-items: center;
}