.manager-page-header {
  margin: 0 0 37px;
  margin-top: 0;
  border-bottom: 1px solid #eee;
}

.manager-parameters {
  display: flex;
  justify-content: space-between;
  margin-top: 27px;
}

.manager-filters {
  display: flex;
}
