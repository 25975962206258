.calendar-day {
  display: flex;
  flex-direction: column;
  border: 1px solid #BFBFBF;
  overflow: hidden;
  padding: 10px;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__weak-day{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    font-size: .9rem;
    text-align: center;
    text-transform: capitalize;
    border-bottom: 1px solid #BFBFBF;
    margin-bottom: 5px;
    padding: 2px;
  }

  &__day {
    width: 100%;
    margin: 0;
    text-align: right;
    font-size: 0.8rem;

    &--current {
      display: grid;
      place-items: center;
      max-width: 20px;
      margin-left: auto;
      border-radius: 50%;
      background: #007bff;
      color: white;
    }
  }

  &__content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 0;
    overflow: scroll;
  }

  &__channel-name {
    cursor: pointer;
  }

  &__event {
    min-height: 50px;
    border:none;
    background: transparent;
    font-size: 0.8rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    margin: 0;
    padding: 0;

    &:hover, {
      text-decoration: underline;
      color: #007BFF;
    }
  }

  &__event-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.7rem;
    background: #f6f6f6;
    border-radius: 10px;
    padding: 5px;
    overflow: hidden;
  }

  &__label {
    display: flex;
    align-items: center;
    padding-left: 1.2em;
    position: relative;
    margin: 0;
  }

  &__radio {
    position: absolute;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &__fake-radio {
    position: absolute;
    margin-top: 0.1em;
    margin-left: -1.2em;
    width: 0.9em;
    height: 0.9em;
    overflow: hidden;
    background-color: white;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 50%;
    border: 1px solid #BFBFBF;

  }

  &__radio:checked + &__fake-radio {
    background-image: url("assets/check-mark.svg");
  }

  &__radio:disabled + &__fake-radio {
    box-shadow: 0 0 0 0.1em #9B9B9B;
  }

  &__radio:checked:disabled + &__fake-radio {
    background-color: #9B9B9B;
  }

  &__time-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__time-label,
  &__date-time {
    font-size: .8rem;
  }

  &__time-label {
    margin-left: .1rem;
  }
}
