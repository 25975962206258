.wrap-header-block {
  display: flex;
  margin-left: auto;
}

#code-review-student-selector {
  width: 200px;
  margin-right: 1rem;
  margin-top: 1rem;
}

.finish-code-review {
  margin-top: 3px;
  margin-right: 4px;
  width: 130px;
  height: 40px;
}
