.chief-collapse-btn {
  min-height: 7em;
  margin: -1px;
  cursor: pointer;
}
.chief-collapse-btn h3 {
  color: white;
}

.chief-block {
  width: 90%;
  border: solid 1px black;
  float: left;
}

.chief-block-content {
  margin: 1px;
}

.list-content {
  padding: 0 !important;
}

.list-content-item {
  margin-bottom: 0 !important;
}

.list {
  margin-top: 30px;
}

.mentor-block {
  border: 1px solid #504f4e;
  border-radius: 3px;
  width: 90%;
  margin-left: auto;
}

.mentor-block-content {
  margin: 1px;
}

.mentor-collapse-btn {
  min-height: 7em;
  margin: -1px;
  cursor: pointer;
}

.list-mentor:active, .list-mentor:focus {
  outline: none;
}

.list-mentor::-moz-focus-inner {
  border: 0;
}

.list-chief:active, .list-chief:focus {
  outline: none;
}

.list-chief::-moz-focus-inner {
  border: 0;
}

.btn-info--gap-left {
  margin-left: 1rem;
}

.container-outer--white {
  background: white;
  border-radius: 5px;
  border: 1px solid #AAAAAA;
  min-height: 138px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-inner {
  padding: 15px;
  justify-self: start;
  align-self: start;
  width: 100%;
}
