

.theory-review-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 115px;
}

.theory-review-page {
  min-height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.theory-review {
  background-color: #edeef0 !important;
  display: flex;
  flex-direction: column;
  width: calc(100% - 500px);
}

.theory-review-content {
  flex: 4;
  height: 90%;
  display: flex;
  flex-direction: row;
}

.theory-review-footer {
  display: flex;
  height: 10%;
  width: 100%;
  justify-content: center;
}

.loader {
  display: flex;
  margin: auto;
}

.right-question-bar {
  display: flex;
  height: 100%;
  min-width: 450px;
  flex-direction: column;
}

.theory-review-wrapper ol > li {
  margin: 0 0 1em 3em;
  padding: 7px 10px;
  background: #D3D3D3;
  border: 1px solid #787878;
  position: relative;
  border-radius: 5px;
}


.theory-review-wrapper ol > li::before {
  background: lightskyblue;
  font-size: 14px;
  border-radius: 50%;
  height: 22px;
  left: -40px;
  line-height: 22px;
  padding: 1px;
  position: absolute;
  text-align: center;
  top: 4px;
  transition: all 0.3s ease 0s;
  width: 22px;
}

.theory-review-wrapper ol {
  height: 80%!important;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 2rem auto 0;
  padding-left: 2rem;
}

.theory-review-wrapper li {
  background: #D3D3D3;
  width: 350px;
  cursor: pointer;
}

.theory-review-wrapper p {
  word-break: break-word;
  text-align: left;
}

.theory-review-wrapper ::-webkit-scrollbar {
  width: 0;
}

.footer-review-container {
  margin-left: auto;
  padding: 2px;
}

.report-download-button {
  border-bottom: 1px dashed #BCBCBC;
  color: #BCBCBC;
  font-size: 18px;
  width: 300px;
  transition: all 200ms;
  cursor: pointer;
  text-align: center;
}
