.select {
  background-color: #9dd5ff !important;
}

.chief-item {
  min-height: 5em;
  background-color: #FFFFFF !important;
}

.chief-item:hover {
  background-color: #9dd5ff !important;
  cursor: pointer;
}

.control-btn {
  /*width: 20px;*/
  padding: 3px;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.control-btn:hover {
  border: 1px solid #5f5bd5;
  border-radius: 3px;
  padding: 4px;
}

.chief-item.select:active.panel-heading, .chief-item.select.panel-heading:focus {
  outline: none;
}
