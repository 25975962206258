.content {

  margin: 20px 0 30px 70px;
  width: 400px;
  height: min-content;
  border: 1px #CCCCCC solid;
  border-radius: 10px;

  .loader {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
  }

}
