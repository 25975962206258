.comment-item {
  width: 100%;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.mentorName {
  height: 20px;
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #000000;
}

.mentorMessage {
  margin: 0;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.comment-button {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4px;
}

.button-edit {
  width: 14px;
  height: 14px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  background: #87CEFA;
  border: 1px solid #787878;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
}

.button-edit:hover > i {
  transform: scale(1.2);
}

.button-clear {
  width: 14px;
  height: 14px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  background: #FF6C6C;
  border: 1px solid #787878;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
}

.button-clear:hover > i {
  transform: scale(1.2);
}

.header-text {
  padding-left: 20px;
}

.comment-edit {
  width: 100%;
  display: grid;
  padding-top: 5px;
  margin: 0 15px 15px 15px;
  border-bottom: 1px solid #787878;
}

.comment-text-edit {
  padding: 3px;
  height: 70px;
  /* width: 98%; */
  /* height: auto; */
  margin: 4px;
  border-radius: 5px;
}

.button-save {
  display: grid;
  grid-row: auto;
  margin: 4px;
  justify-self: end;
  width: 81px;
  height: 34px;
  text-align: center;
  vertical-align: middle;

  background-color: #3988FF;
  border: 1px solid #555555;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.button-save:hover {
  background-color: #337ae5;
}

.comment-button-save-text {
  justify-self: center;
  align-self: center;

  font-size: 14px;
  line-height: 16px;

  color: #FFFFFF;
}
