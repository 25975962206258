.chapter-item {
  min-height: 5em;
}

.chapter-item:hover {
  background-color: #9dd5ff !important;
  cursor: pointer;
}

.control-btn {
  /*width: 20px;*/
  padding: 3px;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.control-btn:hover {
  border: 1px solid #5f5bd5;
  border-radius: 3px;
  padding: 4px;
}

.task-item:hover {
  background-color: #f1f1f1;
}

.row-button {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.chapter-row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
