.available-students {
  width: 50%;
  overflow-y: auto;
  max-height: 650px;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: 2rem;
}

.review-students-table th {
  text-align: center;
}
