.student-debts-list {
  min-width: 459px;
  word-break: break-all;
  display: block;
  height: 200px !important;
  overflow-y: auto;
  margin-right: 1rem;
  counter-reset: li;
  list-style: none;
  padding: 5px 5px 0 0;
}

.student-debts-list > li:hover {
  background-color: rgba(138, 138, 138, 0.5);
}

ol.student-debts-list {
  counter-reset: student-debts-list;
}

ol.student-debts-list > li::before {
  content: counter(student-debts-list);
  counter-increment: student-debts-list;
}

.comment-mentors-block {
  margin: 2rem 6rem;
}

.comment-mentors {
  display: grid;
  grid-template-columns: 10fr 2fr;
  width: 400px;
  height: 54px;
  left: 65px;
  top: 38px;

  background: #D3D3D3;
  border: 1px solid #787878;
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
}

.comment-mentors > div {
  margin: 3px;
  background-color: inherit;
  align-self: center;
}

.comment-mentors > div > p {
  margin: 0;
  background-color: inherit;
  font-size: 16px;
  text-decoration-line: underline;
  color: #000000;
}

.header-icon {
  justify-self: center;
}

i.mdi-chevron-down {
  background-color: inherit;
  font-size: 30px;
  color: #787878;
}

.comment-list {
  display: none;
  width: 400px;
  grid-auto-rows: minmax(70px, auto);
  border-right: 1px solid #787878;
  border-bottom: 1px solid #787878;
  border-left: 1px solid #787878;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
}

h4 {
  margin-left: 15%;
}

.report-download-button {
  margin: auto;
  margin-top: 40px;
}




.drop-down {
  width: 400px;

  &:not(:first-child) {
    margin-top: 40px;
  }

  &__comment {
    display: grid;
    padding-top: 10px;
    margin-bottom: 10px;
  }

  &__comment-edit {
    margin: 4px;
    padding: 3px 5px;
    border-radius: 5px;
  }

  &__button {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 54px;
    padding: 0 20px;
    background-color: #d3d3d3;
    border: 1px solid #787878;
    border-radius: 4px 4px 0px 0px;
    text-align: left;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: #96C2DD;
    }

    &_danger {
      background-color: #DFA9A9;

      &:hover {
        background-color: #E38989;
      }
    }

    &_closed::after {
      content: "";
      position: absolute;
      top: 16px;
      right: 19px;
      width: 15px;
      height: 24px;

      background-image: url('data:image/svg+xml,%3Csvg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0.179993 21.18L9.33999 12L0.179993 2.82L2.99999 0L15 12L2.99999 24L0.179993 21.18Z" fill="%23787878"/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &_opened::after {
      content: "";
      position: absolute;
      top: 21px;
      right: 15px;
      width: 24px;
      height: 15px;

      background-image: url('data:image/svg+xml,%3Csvg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M2.82 0.179932L12 9.33993L21.18 0.179932L24 2.99993L12 14.9999L0 2.99993L2.82 0.179932Z" fill="%23787878"/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &-text {
      display: inline-block;
      max-width: 325px;
      font-size: 16px;
      color: #000;
      text-decoration: underline;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__list {
    list-style: none;
    margin: initial;
    padding: 0 15px;

    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;

    &_shown {
      border: 1px solid #787878;
      border-top: none;
      border-radius: 0px 0px 4px 4px;
    }

    &_danger {
      background-color: #FFE2E2;
    }
  }

  & &__list-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
    background: transparent;
    cursor: initial;
    border-bottom: 1px solid #787878;

    &_highlighted {
      background-color: #E6EEFF;
      margin-left: -15px;
      width: 400px;
      padding: 10px 17px;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__list-item-text {
    font-size: 14px;
    line-height: 16px;
    color: #000;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-bottom: -5px;
  }

  &__control-button {
    position: relative;
    width: 85px;
    padding: 4px 0;
    padding-left: 15px;
    margin-bottom: 5px;
    border: 1px solid #555555;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:only-child:first-child {
      border-radius: 4px;
    }

    &:first-child {
      border-radius: 4px 4px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px 4px 4px
    }

    &_success {
      background-color: #B9F2C6;
      &:hover {
        background-color: #a6d9b2;
      }

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 8px;
        width: 8px;
        height: 7px;
        background-image: url('data:image/svg+xml,%3Csvg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M6.59 1.42904L7.05667 1.88864L2.81 6.07096L0.943333 4.23258L1.41 3.77298L2.81 5.15177L6.59 1.42904ZM6.59 0.5L2.81 4.22273L1.41 2.84394L0 4.23258L2.81 7L8 1.88864L6.59 0.5Z" fill="black"/%3E%3C/svg%3E');
      }
    }

    &_danger {
      background-color: #FFB6B6;
      &:hover {
        background-color: #e5a3a3;
      }

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 8px;
        width: 8px;
        height: 8px;
        background-image: url('data:image/svg+xml,%3Csvg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M7.5 1.205L6.795 0.5L4 3.295L1.205 0.5L0.5 1.205L3.295 4L0.5 6.795L1.205 7.5L4 4.705L6.795 7.5L7.5 6.795L4.705 4L7.5 1.205Z" fill="black"/%3E%3C/svg%3E');
      }
    }
  }

}

