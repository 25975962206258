.test-case-item {
  margin-bottom: 2rem;
  border: 1px solid #4B8AFF;
}

.test-case-item textarea {
  margin: 5px;
}

.test-case-item-header {
  width: 100%;
  height: 3rem;
  border-radius: 0 !important;
}

.actions-block {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: flex-end;*/
  /*align-items: center;*/
  /*max-width: min-content;*/
  max-width: min-content;
  margin: 10px 10px 0 0;
  padding: 0 10px 0 0;
}
