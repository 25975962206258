.manager-page-header {
  margin: 0 0 37px;
  margin-top: 0;
  border-bottom: 1px solid #eee;
}

.manager-parameters {
  display: flex;
  justify-content: space-between;
  margin-top: 27px;
}

.manager-parameters select {
  text-align-last: center;
}

.manager-sort .analytics-options__select {
  width: 200px;
  min-width: 200px;
  border-radius: 10px;
  margin-right: 0;
}