#planning-review-header {
  display: flex;
  margin-left: 30px;
  font-weight: bold;
}

.chief-review-plus-button {
  display: flex;
  color: white;
  background: #009e28;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  justify-content: center;
}

.chief-review-button-container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

#add-chief-review-table-time-input {
  height: 35px;
  margin-left: 5px;
}

.chief-review-button-container i {
  background: #009e28;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.chief-review-button-container .mdi:before, .mdi-set {
  display: flex;
  margin: auto;
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: initial;
}

.chief-section .info-panel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}


