.theory-answer-false {
  border: 1px solid #ffaba5;
  margin: 5px 0;
  padding: 5px;
}

.theory-answer-true {
  border: 1px solid #5bff59;
  margin: 2px 0;
  padding: 5px;
}

.admin-theory-task-answer {
  text-align: start;
  white-space: pre-wrap;
}
