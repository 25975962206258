.chief-review-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
  justify-content: center;
}

.chief-review-table-container > div {
  flex: 1 1 auto;
  margin: 5px auto;
  max-height: 45px;
}

.chief-review-table-time-cell {
  width: 150px;
  height: 65px;
}
