.meet-plan {
  &__calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, 200px);
    min-height: 700px;
    border: 1px solid #BFBFBF;
    background: white;
  }

  &__loader {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
