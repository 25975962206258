.admin-course-list th, td {
  text-align: left;
}

.admin-course-list td {
  text-align: left;
}

.admin-course-list {
  margin-top: 1rem;
}

#create-course-button {
  margin-top: 1rem;
  width: 200px;
  height: 40px;
}
