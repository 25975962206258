.emailArea {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 6px;
  border: 1px solid #ABABAB;
  padding: 8px;
  border-radius: 4px;
  word-break: break-all;
  overflow-y: auto;
  height: 200px;
  margin-bottom: 8px;
}

.emailArea:hover {
  cursor: text;
}

.studentEmail {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px;
  line-height: 1;
  border-radius: 6px;
}

.emailCorrect {
  background-color: #88D4FF;
}

.emailIncorrect {
  background-color: #ff8888;
}

.emailDeleteBtn {
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  font-weight: 700;
}

.emailDeleteBtn::after {
  content: '';
  display: block;
  height: 12px;
  width: 12px;
  background-image: url("../assets/cross.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.hiddenInput {
  outline: none;
  border: none;
  background: transparent;
  width: 1.5em;
  flex-grow: 1;
}

.modalBtns {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 8px;

  button {
    font-size: 14px;
  }
}
